import { useCallback, useState } from 'react';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from 'react-router-dom';
import { toastConfig } from 'utils/toast.config';
import { UserRole } from '../utils/types';
import { urlConfig } from '../utils/url-config';

export const useAuth = () => {
	const signIn = useSignIn();
	const navigate = useNavigate();
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

	const login = useCallback<(params: { authState: any; callbackUrl: string | undefined }) => Promise<void>>(
		async ({ authState, callbackUrl }) => {
			try {
				if (!authState.user_password || !authState.user_email) {
					throw new Error('Invalid auth state. Password and email are required');
				}

				setIsButtonDisabled(true);

				const resPromise = await fetch(`${urlConfig.API_URL}${urlConfig.api.login}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						user_email: authState.user_email,
						user_password: authState.user_password,
					}),
				});
				if (!resPromise.ok) {
					toastConfig.login.error();
					throw new Error('Response error');
				}

				const res = (await resPromise.json()) as {
					access_token: string;
					user: {
						user_id: string;
						user_name: string;
						user_email: string;
						user_role: UserRole;
						is_verified: boolean;
					};
				};

				if (!res.access_token) {
					toastConfig.login.error();
					throw new Error('Invalid response data');
				}

				signIn({
					auth: {
						token: res.access_token,
						type: 'Bearer',
					},
					userState: {
						...res,
					},
				});
				toastConfig.login.success(res.user.user_name);

				localStorage.setItem('token', res.access_token);
				if (callbackUrl) {
					navigate(callbackUrl);
				}
			} catch (error) {
				toastConfig.login.error();
				console.error(error);
			} finally {
				setIsButtonDisabled(false);
			}
		},
		[navigate, signIn]
	);

	const register = useCallback<(params: { authState: any; callbackUrl: string | undefined }) => Promise<void>>(
		async ({ authState, callbackUrl }) => {
			try {
				if (!authState.user_password || !authState.user_email || !authState.user_name) {
					throw new Error('Invalid auth state. Password and email are required');
				}

				setIsButtonDisabled(true);

				const resPromise = await fetch(`${urlConfig.API_URL}${urlConfig.api.register}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						user_email: authState.user_email,
						user_password: authState.user_password,
						user_name: authState.user_name,
					}),
				});
				if (!resPromise.ok) {
					toastConfig.register.error();
					throw new Error('Response error');
				}

				const res = (await resPromise.json()) as {
					access_token: string;
					user: {
						user_id: string;
						user_name: string;
						user_email: string;
						user_role: UserRole;
						is_verified: boolean;
					};
				};

				if (!res.access_token) {
					toastConfig.register.error();
					throw new Error('Invalid response data');
				}

				signIn({
					auth: {
						token: res.access_token,
						type: 'Bearer',
					},
					userState: {
						...res,
					},
				});
				localStorage.setItem('token', res.access_token);
				toastConfig.register.success(res.user.user_name);
				if (callbackUrl) {
					navigate(callbackUrl);
				}
			} catch (error) {
				toastConfig.register.error();
				console.error(error);
			} finally {
				setIsButtonDisabled(false);
			}
		},
		[navigate, signIn]
	);

	const signOut = useSignOut();

	return {
		isButtonDisabled,
		login,
		register,
		signOut,
	} as const;
};
