import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from 'components/ui/button';
import { Card, CardContent, CardHeader } from 'components/ui/card';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { useAuth } from 'global-hooks/use-auth';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { urlConfig } from 'utils/url-config';
import { z } from 'zod';

const formSchema = z.object({
	user_email: z.string().min(2).max(50).email(),
	user_password: z.string().min(6).max(50),
	user_password_confirm: z.string().min(6).max(50),
	user_name: z.string().min(2).max(50),
});

const Register = () => {
	const { isButtonDisabled, register } = useAuth();

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			user_email: '',
			user_password: '',
			user_password_confirm: '',
			user_name: '',
		},
	});

	function onSubmit(values: z.infer<typeof formSchema>) {
		if (values.user_password === values.user_password_confirm) {
			register({ authState: values, callbackUrl: urlConfig.pages.main });
		}
	}
	return (
		<div className="w-screen h-screen flex items-center justify-center">
			<Card>
				<CardHeader>
					<h2 className="text-2xl font-bold">Sign up</h2>
					<span>Enter your name, email and password to sign up</span>
				</CardHeader>
				<CardContent>
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-3">
							<FormField
								control={form.control}
								name="user_name"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Name</FormLabel>
										<FormControl>
											<Input placeholder="John Doe" type="text" {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="user_email"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Email</FormLabel>
										<FormControl>
											<Input placeholder="example@email.com" type="email" {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="user_password"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Password</FormLabel>
										<FormControl>
											<Input placeholder="******" type="password" {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="user_password_confirm"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Confirm password</FormLabel>
										<FormControl>
											<Input placeholder="******" type="password" {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>

							<div className="flex flex-col gap-2">
								<Button type="submit" className="w-full" disabled={isButtonDisabled}>
									Submit
								</Button>
								<Link to={urlConfig.pages.login} className="text-sm text-blue-800 text-center">
									Already have an account? Sign in
								</Link>
							</div>
						</form>
					</Form>
				</CardContent>
			</Card>
		</div>
	);
};
export default Register;
