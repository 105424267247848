import { Toaster } from 'components/ui/toaster';
import { Routes } from './routes';

function App() {
	return (
		<div className=" mx-auto">
			{/* <Navbar /> */}
			<Routes />
			<Toaster />
		</div>
	);
}

export default App;
