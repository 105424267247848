import { AuthUser } from 'auth-provider/require-auth';
import { Logo } from 'components/Logo';
import { Telegram } from 'components/Telegram';
import { Button } from 'components/ui/button';
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import { useAuth } from 'global-hooks/use-auth';
import { memo, useEffect, useRef, useState } from 'react';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import { Outlet, useNavigate } from 'react-router';
import { usePostsStore } from 'stores/post-store';
import { cities } from 'utils/cities';
import { cn } from 'utils/cn';
import { UserRole } from 'utils/types';
import { urlConfig } from 'utils/url-config';

type Props = {
	className?: string;
	mapView?: boolean;
};

const calculatePaddingClass = (ref: React.RefObject<HTMLDivElement>): string => {
	if (!ref.current) return '';

	return ref.current.clientHeight < 90
		? `pt-[72px]`
		: ref.current.clientHeight > 90 && ref.current.clientHeight < 100
		? `pt-[104px]`
		: ref.current.clientHeight > 100 && ref.current.clientHeight < 120
		? `pt-[112px]`
		: `pt-[210px]`;
};

const ApartmentsWrapper: React.FC<Props> = ({ className, mapView, ...props }) => {
	const { signOut } = useAuth();
	const isAuth = useIsAuthenticated();
	const auth = useAuthUser<AuthUser>();

	const postCity = usePostsStore((state) => state.postCity);
	const setPostCity = usePostsStore((state) => state.setPostCity);
	const ref = useRef<HTMLDivElement | null>(null);

	const [paddingClass, setPaddingClass] = useState<string>('');

	const path = window.location.pathname;

	useEffect(() => {
		const updatePadding = () => {
			setPaddingClass(calculatePaddingClass(ref));
		};
		updatePadding();

		const resizeObserver = new ResizeObserver(updatePadding);
		if (ref.current) {
			resizeObserver.observe(ref.current);
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, [paddingClass]);

	const navigate = useNavigate();

	return (
		<div className={cn('h-svh max-h-svh overflow-y-auto relative sm:border-l border-gray-200 ', className)}>
			<div
				ref={ref}
				className={cn(
					'fixed z-[51] md:w-2/5 p-2 border-b border-gray-200 flex-wrap gap-2 bg-white/90',
					mapView ? 'w-0' : 'w-full'
				)}>
				<div className="flex flex-col gap-3">
					<div className="flex w-full items-center justify-between flex-wrap sm:flex-nowrap">
						<Logo onClick={() => navigate(urlConfig.pages.main)} className="cursor-pointer order-1" />

						<div className="flex items-center justify-end gap-2 flex-wrap order-2 md:order-3">
							{isAuth && (auth?.user?.user_role === UserRole.ADMIN || auth?.user?.user_role === UserRole.MANAGER) && (
								<Button
									variant="outline"
									size="sm"
									className="flex gap-2 items-center text-sm"
									onClick={() => {
										navigate(urlConfig.pages.admin);
									}}>
									Admin page
								</Button>
							)}
							{isAuth && (
								<Button
									variant="outline"
									size="sm"
									className="flex gap-2 items-center text-sm"
									onClick={() => {
										navigate(urlConfig.pages.addPost);
									}}>
									Create post
								</Button>
							)}
							<Button
								variant="outline"
								size="sm"
								className="flex gap-2 items-center text-sm"
								onClick={() => {
									window.open('https://t.me/tbilisimap', '_blank');
								}}>
								<Telegram />
								Telegram
							</Button>
							{!isAuth ? (
								<Button
									variant="outline"
									size="sm"
									className="flex gap-1 items-center text-sm"
									onClick={() => navigate(urlConfig.pages.login)}>
									Sign in
								</Button>
							) : (
								<Button
									variant="outline"
									size="sm"
									className="flex gap-1 items-center text-sm"
									onClick={() => {
										signOut();
										navigate(urlConfig.pages.main);
									}}>
									Sign out
								</Button>
							)}
						</div>
						{path === '/' && (
							<Tabs defaultValue={postCity} className="w-full pl-[16px] sm:w-fit order-3 md:order-2 mt-2 sm:mt-0">
								<TabsList className="grid w-full grid-cols-2">
									{cities.map((city) => (
										<TabsTrigger
											key={city.value}
											value={city.value}
											className="px-6"
											onClick={() => {
												setPostCity(city.value);
											}}>
											{city.label}
										</TabsTrigger>
									))}
								</TabsList>
							</Tabs>
						)}
					</div>
				</div>
			</div>
			<div className={cn('flex flex-col pt-16', paddingClass)}>
				<Outlet />
			</div>
		</div>
	);
};
export default memo(ApartmentsWrapper);
