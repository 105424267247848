export const cities = [
	{
		value: 'tbilisi',
		label: 'Tbilisi',
		coordinates: {
			lat: 41.706341,
			lng: 44.79021,
		},
	},
	{ value: 'batumi', label: 'Batumi', coordinates: { lat: 41.646819, lng: 41.638119 } },
];
