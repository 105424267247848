import { CardTitle } from 'components/ui/card';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { useState } from 'react';
import { usePostsStore } from 'stores/post-store';

const PostFilters = () => {
    const minPrice = usePostsStore((state) => state.postMinPrice);
    const maxPrice = usePostsStore((state) => state.postMaxPrice);
    const metroStation = usePostsStore((state) => state.postMetroStation);
    const bedrooms = usePostsStore((state) => state.postBedrooms);
    const pets = usePostsStore((state) => state.postPets);
    const rooms = usePostsStore((state) => state.postRooms);
    const tenants = usePostsStore((state) => state.postTenants);
    const minMeters = usePostsStore((state) => state.postMinMeters);
    const maxMeters = usePostsStore((state) => state.postMaxMeters);
    const floor = usePostsStore((state) => state.postFloor);

    const setMinPrice = usePostsStore((state) => state.setPostMinPrice);
    const setMaxPrice = usePostsStore((state) => state.setPostMaxPrice);
    const setMetroStation = usePostsStore((state) => state.setPostMetroStation);
    const setBedrooms = usePostsStore((state) => state.setPostBedrooms);
    const setPets = usePostsStore((state) => state.setPostPets);
    const setRooms = usePostsStore((state) => state.setPostRooms);
    const setTenants = usePostsStore((state) => state.setPostTenants);
    const setMinMeters = usePostsStore((state) => state.setPostMinMeters);
    const setMaxMeters = usePostsStore((state) => state.setPostMaxMeters);
    const setFloor = usePostsStore((state) => state.setPostFloor);
    const setOpenFilter = usePostsStore((state) => state.setOpenFilter)
    const handleMinPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        setMinPrice(isNaN(value) ? null : value);
    };

    

    const handleMaxPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        setMaxPrice(isNaN(value) ? null : value);
    };
    

    const handleMetroChange = (e: any) => {
        setMetroStation(e.target.value);
    };
    

    const handleBedroom = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        setBedrooms(isNaN(value) ? null : value);
    };
    
    const handlePetsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPets(value);
    };

    const handleRooms = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        setRooms(isNaN(value) ? null : value);
    };
    
    const handleTenants = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        setTenants(isNaN(value) ? null : value);
    };

    const handleMinMeters = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        setMinMeters(isNaN(value) ? null : value);
    };

    

    const handleMaxMeters = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        setMaxMeters(isNaN(value) ? null : value);
    };

    const handleFloor = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        setFloor(isNaN(value) ? null : value);
    };

    return (
        <div className="flex border-none bg-white border-black z-[9999] w-full h-[100vh] flex-col">
            <CardTitle className='m-2 border-b border-gray-400/30 pb-2'>Post filtering</CardTitle>
            <span className='text-sm text-gray-400 mx-2'>Find what you are looking for thanks to filters</span>
            <div className='mx-2'>
            <div className='flex gap-5'>
                <div className='flex w-full flex-col gap-3 mt-3'>
                    <Label>Min Price</Label>
                    <Input
                        type="number"
                        min={1}
                        placeholder="Min Price"
                        value={minPrice ?? ''}
                        onChange={handleMinPriceChange}
                        className="border rounded-md px-2 py-1"
                    />
                </div>
                
                <div className='flex w-full flex-col gap-3 mt-3'>
                    <Label>Max Price</Label>
                    <Input
                        type="number"
                        placeholder="Max Price"
                        value={maxPrice ?? ''}
                        onChange={handleMaxPriceChange}
                        className="border rounded-md px-2 py-1"
                    />
                </div>
                  
                </div>
                
                <div className='flex flex-col gap-3 mt-3'>
                    <Label>Metro station</Label>
                    <Input
                    id='Metro'
                        type="text"
                        placeholder="Metro station"
                        value={metroStation ?? ''}
                        onChange={handleMetroChange}
                        className="border rounded-md px-2 py-1"
                    />
                </div>
                <div className='flex flex-col gap-3 mt-3'>
                    <Label>Rooms</Label>
                    <Input
                        type="number"
                        placeholder="Rooms"
                        value={rooms ?? ''}
                        onChange={handleRooms}
                        className="border rounded-md px-2 py-1"
                    />
                </div>
                <div className='flex flex-col gap-3 mt-3'>
                    <Label>Bedrooms</Label>
                    <Input
                        type="number"
                        placeholder="Bedrooms"
                        value={bedrooms ?? ''}
                        onChange={handleBedroom }
                        className="border rounded-md px-2 py-1"
                    />
                </div>
                <div className='flex flex-col gap-3 mt-3'>
                    <Label>Tenants</Label>
                    <Input
                        type="number"
                        placeholder="Tenants"
                        value={tenants ?? ''}
                        onChange={handleTenants }
                        className="border rounded-md px-2 py-1"
                    />
                </div>


                <div className='flex gap-5'>
                <div className='flex w-full flex-col gap-3 mt-3'>
                    <Label>Min sq-meters</Label>
                    <Input
                        type="number"
                        min={1}
                        placeholder="Min meters"
                        value={minMeters ?? ''}
                        onChange={handleMinMeters}
                        className="border rounded-md px-2 py-1"
                    />
                </div>
                
                <div className='flex w-full flex-col gap-3 mt-3'>
                    <Label>Max sq-meters</Label>
                    <Input
                        type="number"
                        placeholder="Max meters"
                        value={maxMeters ?? ''}
                        onChange={handleMaxMeters}
                        className="border rounded-md px-2 py-1"
                    />
                </div>
                  
                </div>
                <div className='flex flex-col gap-3 mt-3'>
                    <Label>Floor</Label>
                    <Input
                        type="number"
                        placeholder="Floor"
                        value={floor ?? ''}
                        onChange={handleFloor }
                        className="border rounded-md px-2 py-1"
                    />
                </div>
                <div className='flex flex-col gap-3 mt-3'>
                    <Label>Pets</Label>
                   <div className='flex flex-row items-center gap-7'> 
                    <label className='flex flex-row gap-2'>
                        <input
                            type="radio"
                            name="pets"
                            value="by-agreement"
                            checked={pets === 'by-agreement'}
                            onChange={handlePetsChange}
                        />
                        by-agreement
                    </label>
                    <label className='flex flex-row gap-2'>
                        <input
                            type="radio"
                            name="pets"
                            value="no-pets"
                            checked={pets === 'no-pets'}
                            onChange={handlePetsChange}
                        />
                        no-pets
                    </label>
                   </div>
                   <div className='flex justify-center bottom-0'>
                   <button onClick={() => setOpenFilter(false)} className='bg-black/90 hover:bg-black/40 rounded-lg duration-500 text-white border h-14 flex items-center justify-center w-[20%]'>Back to posts</button>
                   </div>
                </div>
                
               
            </div>
        </div>
    );
};

export default PostFilters;
