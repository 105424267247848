import Loader from 'components/Loader';
import { Separator } from 'components/ui/separator';
import { AuthUser } from 'auth-provider/require-auth';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { Fragment } from 'react/jsx-runtime';
import { usePostsStore } from 'stores/post-store';
import { cn } from 'utils/cn';
import { UserRole } from 'utils/types';
import useGetPosts from './hooks/use-get-posts';
import { Badge } from 'components/ui/badge';
import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import PostFIlters from './post-filters';

const Post = lazy(() => import('./post'));

function PostsList() {
    const auth = useAuthUser<AuthUser>();
    const path = window.location.pathname;
    const postId = usePostsStore((state) => state.postId);
    const postCity = usePostsStore((state) => state.postCity);
    const minPrice = usePostsStore((state) => state.postMinPrice);
    const maxPrice = usePostsStore((state) => state.postMaxPrice);
    const metro = usePostsStore((state) => state.postMetroStation);
    const bedrooms = usePostsStore((state) => state.postBedrooms);
    const pets = usePostsStore((state) => state.postPets);
    const rooms = usePostsStore((state) => state.postRooms);
    const tenants = usePostsStore((state) => state.postTenants);
    const floor = usePostsStore((state) => state.postFloor);
    const setMinPrice = usePostsStore((state) => state.setPostMinPrice)
    const setMaxPrice = usePostsStore((state) => state.setPostMaxPrice)
    const postDealType = usePostsStore((state) => state.postDealType);
    const setPostDealType = usePostsStore((state) => state.setPostDealType);
    
    const [filteredPosts, setFilteredPosts] = useState<any[]>([]);
    const [favouritesPosts, setFavouritesPosts] = useState<any[]>([]);
    const minMeters = usePostsStore((state) => state.postMinMeters);
    const maxMeters = usePostsStore((state) => state.postMaxMeters);
    const openFilter = usePostsStore((state) => state.openFilter);
    const setOpenFilter = usePostsStore((state) => state.setOpenFilter);

    const scrollRef = useRef<HTMLDivElement | null>(null);
    const favItem = localStorage.getItem('safeToPostIds');
    const safeToPostIds = favItem ? JSON.parse(favItem) : [];
    const countOfFavorites = safeToPostIds.length;
    const { isLoading, posts, refetch } = useGetPosts();
    const [ref, inView] = useInView();
    const [priceFilter, setPriceFilter] = useState<number | null>(null);
    // const [minPrice, setMinPrice] = useState<number | null>(null);
    // const [maxPrice, setMaxPrice] = useState<number | null>(null);

    useEffect(() => {
        refetch();
     }, [refetch, posts]);


     useEffect(() => {
        console.log('MIN PRICE', minPrice, 'MAX PRICE', maxPrice, bedrooms, pets);
        
     },[bedrooms, pets])

    useEffect(() => {
        if (postDealType === 'favorites') {
            const saveToPostIdsString = localStorage.getItem('safeToPostIds');
            const saveToPostIds = saveToPostIdsString ? JSON.parse(saveToPostIdsString) : [];
            setFavouritesPosts(posts.filter((post: any) => saveToPostIds.includes(post.post_id)));
            refetch();
        } else {
            setFavouritesPosts([]);
            refetch();
        }
    }, [postDealType, posts, refetch])

    useEffect(() => {
        if (posts) {
            // const metro = 'Liberty Square'; 
            // const maxPrice = 500; 
        
            if (auth && auth?.user?.user_role === UserRole.USER) {
                setFilteredPosts(
                    posts.filter(
                        (post: any) =>
                            post.user.user_id === auth?.user?.user_id &&
                            post.is_approved &&
                            post.post_city === postCity &&
                            (postDealType !== 'none' ? post.post_deal_type === postDealType : post.post_deal_type !== 'none') &&
                            (!minPrice || (post.post_price >= minPrice && (!maxPrice || post.post_price <= maxPrice))) &&
                            (!metro || (post.post_metro_station === metro)) &&
                            (!bedrooms?.toString() || (post.post_bedroom === bedrooms?.toString())) &&
                            (!pets || (post.post_pets === pets)) &&
                            (!rooms?.toString() || (post.post_rooms === rooms?.toString())) &&
                            (!tenants?.toString() || (post.post_tenants_max >= tenants?.toString())) &&
                            (!minMeters || (post.post_sq_meters >= minMeters && (!maxMeters || post.post_sq_meters <= maxMeters))) &&
                            (!floor?.toString() || (post.post_floor === floor?.toString()))
                            
                    )
                );
            } else if ((auth && auth?.user?.user_role === UserRole.ADMIN) || auth?.user?.user_role === UserRole.MANAGER) {
                setFilteredPosts(
                    posts.filter(
                        (post: any) =>
                            post.post_city === postCity &&
                            (postDealType !== 'none' ? post.post_deal_type === postDealType : post.post_deal_type !== 'none') &&
                            (!minPrice || (post.post_price >= minPrice && (!maxPrice || post.post_price <= maxPrice))) &&
                            (!metro || (post.post_metro_station === metro)) &&
                            (!bedrooms?.toString() || (post.post_bedroom === bedrooms?.toString())) &&
                            (!pets || (post.post_pets === pets)) &&
                            (!rooms?.toString() || (post.post_rooms === rooms?.toString())) &&
                            (!tenants?.toString() || (post.post_tenants_max >= tenants?.toString())) &&
                            (!minMeters || (post.post_sq_meters >= minMeters && (!maxMeters || post.post_sq_meters <= maxMeters))) &&
                            (!floor?.toString() || (post.post_floor === floor?.toString()))
                    )
                );
            } else {
                setFilteredPosts(
                    posts.filter(
                        (post: any) =>
                            post.is_approved &&
                            post.post_city === postCity &&
                            (postDealType !== 'none' ? post.post_deal_type === postDealType : post.post_deal_type !== 'none') &&
                            (!minPrice || (post.post_price >= minPrice && (!maxPrice || post.post_price <= maxPrice))) &&
                            (!metro || (post.post_metro_station === metro)) &&
                            (!bedrooms?.toString() || (post.post_bedroom === bedrooms?.toString())) &&
                            (!pets || (post.post_pets === pets)) &&
                            (!rooms?.toString() || (post.post_rooms === rooms?.toString())) &&
                            (!tenants?.toString() || (post.post_tenants_max >= tenants?.toString())) &&
                            (!minMeters || (post.post_sq_meters >= minMeters && (!maxMeters || post.post_sq_meters <= maxMeters))) &&
                            (!floor?.toString() || (post.post_floor === floor?.toString()))
                    )
                );
            }
        }
        
        
        
    }, [auth, auth?.user?.user_id, auth?.user?.user_role, postCity, postDealType, posts, minPrice, maxPrice, metro, bedrooms, pets, rooms, tenants, minMeters, maxMeters, floor]);

    useEffect(() => {
        if (postId && scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [postId]);

    const handleMinPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        setMinPrice(isNaN(value) ? null : value);
    };
    
    const handleMaxPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        setMaxPrice(isNaN(value) ? null : value);
    };
    

    const handleCLick = () => {
        setOpenFilter(!openFilter);
    }

    if (isLoading) {
        return (
            <div className="flex justify-center items-center mt-20">
                <Loader />
            </div>
        );
    }

    


    return (
        <div className="flex flex-col ">
            <div className="">
                {path === '/' && (
                    <div className="flex justify-between">
                        <div className="w-full order-4 flex items-center gap-3 mr-5 justify-between">

                            <div className="flex ml-2 gap-2 items-center">
                                <Badge
                                    variant={postDealType === 'rent' ? 'default' : 'outline'}
                                    className="cursor-pointer text-base"
                                    onClick={() => {
                                        setPostDealType('rent');
                                        setOpenFilter(false);
                                        refetch();
                                    }}>
                                    Rent
                                </Badge>
                                <Badge
                                    variant={postDealType === 'daily' ? 'default' : 'outline'}
                                    className="cursor-pointer text-base"
                                    onClick={() => {
                                        setPostDealType('daily');
                                        setOpenFilter(false);
                                        refetch();
                                    }}>
                                    Daily
                                </Badge>
                               
                                <Badge
                                   variant={postDealType === 'sale' ? 'default' : 'outline'}
                                    className="cursor-pointer text-base"
                                    onClick={() => {
                                        setPostDealType('sale');
                                        setOpenFilter(false);
                                        refetch();
                                    }}>
                                    Sale
                                </Badge>
                                <Badge
                                   
                                   className="cursor-pointer hover:bg-black/10 bg-white text-black text-base"
                                   onClick={handleCLick}>
                                   <Filter/>
                               </Badge>
                            </div>
                            <span className="text-sm font-semibold p-2">
                            {postDealType === 'favorites' ? favouritesPosts.length : filteredPosts?.length} result
                            {filteredPosts?.length > 1 || favouritesPosts?.length > 1 ? 's' : ''}

                            </span>
                            <Badge
                                variant={postDealType === 'favorites' ? 'default' : 'outline'}
                                className="cursor-pointer"
                                onClick={() => {
                                    setPostDealType('favorites');
                                }}>
                                {`Favorite posts: ${countOfFavorites}`}
                                {postDealType === 'favorites' ?
                                    <svg className='ml-1' width="12" height="12" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 24 24"><path d="M12 21.593c-5.63-5.539-11-10.297-11-14.402 0-3.791 3.068-5.191 5.281-5.191 1.312 0 4.151.501 5.719 4.457 1.59-3.968 4.464-4.447 5.726-4.447 2.54 0 5.274 1.621 5.274 5.181 0 4.069-5.136 8.625-11 14.402m5.726-20.583c-2.203 0-4.446 1.042-5.726 3.238-1.285-2.206-3.522-3.248-5.719-3.248-3.183 0-6.281 2.187-6.281 6.191 0 4.661 5.571 9.429 12 15.809 6.43-6.38 12-11.148 12-15.809 0-4.011-3.095-6.181-6.274-6.181"/></svg>
                                    :
                                    <svg className='ml-1' xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/></svg>
                                }
                            </Badge>
                        </div>
                    </div>
                )}
            </div>

            <div className="flex flex-col pb-12 md:pb-0">
            {/* <div className="flex justify-between">
            <input
    type="number"
    placeholder="Min Price"
    value={minPrice || ''}
    onChange={handleMinPriceChange}
    className="border rounded-md px-2 py-1 mr-2"
/>

<input
    type="number"
    placeholder="Max Price"
    value={maxPrice || ''}
    onChange={handleMaxPriceChange}
    className="border rounded-md px-2 py-1"
/>

                </div> */}
                <Suspense fallback={<Loader />}>
               
                <div className='w-full'>
                    {openFilter && <PostFIlters/>}
                </div>
                {!openFilter &&
        <div>
                {postDealType === 'favorites' ? (
                        <>
                            {favouritesPosts?.map((post: any, index: number) => {
                                
                                return (
                                    <Fragment key={post.post_id}>
                                        <Post
                                            post={post}
                                            auth={auth}
                                            postClasses={cn(
                                                'flex-col lg:flex-row w-full rounded-md',
                                                postId === post.post_id && 'border-2 border-zinc-700',
                                                'hover:border-[1px] hover:border-zinc-700',
                                                'transition-all '
                                            )}
                                            sliderClasses="w-full lg:w-56"
                                            scrollRef={postId === post.post_id ? scrollRef : null}
                                        />

                                        {filteredPosts.length > 1 && index !== filteredPosts.length - 1 && (
                                            <Separator className="mx-auto w-[95%] my-1" />
                                        )}
                                    </Fragment>
                                );
                            })}
                        </>
                    ) : (
                        <>
                            {filteredPosts?.map((post: any, index: number) => {
                                return (
                                    <Fragment key={post.post_id}>
                                        <Post
                                            post={post}
                                            auth={auth}
                                            postClasses={cn(
                                                'flex-col lg:flex-row w-full rounded-md',
                                                postId === post.post_id && 'border-2 border-zinc-700',
                                                'hover:border-[1px] hover:border-zinc-700',
                                                'transition-all '
                                            )}
                                            sliderClasses="w-full lg:w-56"
                                            scrollRef={postId === post.post_id ? scrollRef : null}
                                        />

                                        {filteredPosts.length > 1 && index !== filteredPosts.length - 1 && (
                                            <Separator className="mx-auto w-[95%] my-1" />
                                        )}
                                    </Fragment>
                                );
                            })}
                        </>
                    )}
            </div>
}
                </Suspense>
            </div>
            
        </div>
    );
}

export default PostsList;



export const Filter = () => {
return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-sliders-horizontal"><line x1="21" x2="14" y1="4" y2="4"/><line x1="10" x2="3" y1="4" y2="4"/><line x1="21" x2="12" y1="12" y2="12"/><line x1="8" x2="3" y1="12" y2="12"/><line x1="21" x2="16" y1="20" y2="20"/><line x1="12" x2="3" y1="20" y2="20"/><line x1="14" x2="14" y1="2" y2="6"/><line x1="8" x2="8" y1="10" y2="14"/><line x1="16" x2="16" y1="18" y2="22"/></svg>
}