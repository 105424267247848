import { create } from 'zustand';

interface PostsState {
	coordinates: {
		lat: number;
		lng: number;
	};
	postId: string | null;
	postCity: string;
	postDealType: string;
	fromMobilePin: boolean;
	postMinPrice: number | null;
	postMaxPrice:number | null;
	postMetroStation: string | null;
	postBedrooms: number | null;
	postPets: string | null;
	postRooms: number | null;
	postTenants: number | null;
	postMinMeters: number | null;
	postMaxMeters: number | null;
	postFloor: number | null;
	openFilter: boolean;
	
	setPostDealType: (dealType: string) => void;
	clearPostDealType: () => void;

	setPostId: (id: string) => void;
	clearPostId: () => void;

	updateCoordinates: (lat: number, lng: number) => void;
	clearCoordinates: () => void;

	setPostCity: (city: string) => void;

	setFromMobilePin: (fromMobilePin: boolean) => void;
	setPostMinPrice: (postMinPrice: number | null) => void;
	setPostMaxPrice: (postMaxPrice: number | null) => void;
	setPostMetroStation: (postMetroStation: string | null) => void;
	setPostBedrooms: (postBedrooms: number | null) => void;
	setPostPets: (postPets: string | null) => void;
	setPostRooms: (postRooms: number | null) => void;
	setPostTenants: (postTenants: number | null) => void;
	setPostMinMeters: (postMinMeters: number | null) => void;
	setPostMaxMeters: (postMaxMeters: number | null) => void;
	setPostFloor: (postFloor: number | null) => void;
	setOpenFilter: (openFilter: boolean) => void;
}

export const usePostsStore = create<PostsState>()((set) => ({
	coordinates: {
		lat: 0,
		lng: 0,
	},
	postId: null,
	postCity: 'tbilisi',
	postDealType: 'rent',
	fromMobilePin: false,
	postMinPrice: 1,
	postMaxPrice: null,
	postMetroStation: null,
	postBedrooms: null,
	postPets: null,
	postRooms: null,
	postTenants: null,
	postMinMeters: 1,
	postMaxMeters: null,
	postFloor: null,
	openFilter: false,
	//

	setPostDealType: (dealType) => set({ postDealType: dealType }),
	clearPostDealType: () => set({ postDealType: 'none' }),

	setPostId: (id: string) => set({ postId: id }),
	clearPostId: () => set({ postId: null }),

	updateCoordinates: (lat, lng) =>
		set((state) => ({
			coordinates: { ...state.coordinates, lat: lat, lng: lng },
		})),
	clearCoordinates: () => set({ coordinates: { lat: 0, lng: 0 } }),

	setPostCity: (city) => set({ postCity: city }),
	setFromMobilePin: (newFromMobilePin) => set({ fromMobilePin: newFromMobilePin }),
	setPostMinPrice: (newPostMinPrice) => set({ postMinPrice: newPostMinPrice}),
	setPostMaxPrice: (newPostMaxPrice) => set({ postMaxPrice: newPostMaxPrice}),
	setPostMetroStation: (newPostMetroStation) => set({ postMetroStation: newPostMetroStation}),
	setPostBedrooms: (newPostBedrooms) => set({ postBedrooms: newPostBedrooms}),
	setPostPets: (newPostPets) => set({postPets: newPostPets}),
	setPostRooms: (newPostRooms) => set({postRooms: newPostRooms}),
	setPostTenants: (newPostTenants) => set({postTenants: newPostTenants}),
	setPostMinMeters: (newPostMinMeters) => set({ postMinMeters: newPostMinMeters}),
	setPostMaxMeters: (newPostMaxMeters) => set({ postMaxMeters: newPostMaxMeters}),
	setPostFloor: (newPostFloor) => set({postFloor: newPostFloor}),
	setOpenFilter: (newOpenFilter) => set({openFilter: newOpenFilter}),
}));
