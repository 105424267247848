import { zodResolver } from '@hookform/resolvers/zod';
import { AuthUser } from 'auth-provider/require-auth';
import Loader from 'components/Loader';
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from 'components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { toast } from 'components/ui/use-toast';
import useDeleteUser from 'modules/admin/hooks/use-delete-user';
import useUpdateUser from 'modules/admin/hooks/use-update-user';
import { memo } from 'react';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useForm } from 'react-hook-form';
import { UserRole } from 'utils/types';
import { z } from 'zod';

type AdminActionsProps = {
	id?: string;
	role?: string;
	refetch?: () => void;
};

const formSchema = z.object({
	user_role: z.nativeEnum(UserRole),
});

const AdminActions: React.FC<AdminActionsProps> = ({ id, role, refetch, ...props }) => {
	const auth = useAuthUser<AuthUser>();

	const { mutate } = useDeleteUser(refetch as () => void);
	const { mutate: updateUser, isLoading: isLoadingUpdate } = useUpdateUser(refetch as () => void);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			user_role: role as UserRole,
		},
	});

	function onSubmit(values: z.infer<typeof formSchema>) {
		if (values.user_role === role) {
			toast({
				title: 'Role is already set',
				description: 'Please select a different role',
				variant: 'warning',
			});
			return;
		}
		updateUser({ user_id: id as string, ...values });
	}

	if (isLoadingUpdate) {
		return (
			<div className="flex justify-center items-center">
				<Loader />
			</div>
		);
	}

	return (
		<div className="flex gap-2 items-center">
			<AlertDialog>
				<AlertDialogTrigger asChild>
					<Button variant="destructive" size="sm">
						Delete
					</Button>
				</AlertDialogTrigger>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
						<AlertDialogDescription>
							This action cannot be undone. This will permanently delete user from our servers.
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel>Cancel</AlertDialogCancel>
						<AlertDialogAction onClick={() => mutate(id as string)} className="bg-destructive hover:bg-destructive/90">
							Delete
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>

			<Dialog>
				<DialogTrigger asChild>
					<Button variant="outline" size="sm">
						Edit role
					</Button>
				</DialogTrigger>
				<DialogContent className="sm:max-w-[425px]">
					<DialogHeader>
						<DialogTitle>Edit profile</DialogTitle>
						<DialogDescription>Change user role here. Click save when you're done.</DialogDescription>
					</DialogHeader>
					<div className="">
						<Form {...form}>
							<form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-3">
								<FormField
									control={form.control}
									name="user_role"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Role</FormLabel>
											<Select
												disabled={auth?.user?.user_role !== UserRole.ADMIN && role === UserRole.ADMIN}
												onValueChange={field.onChange}
												defaultValue={field.value}
												required>
												<FormControl>
													<SelectTrigger>
														<SelectValue defaultValue={UserRole.USER} className="capitalize" />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{Object.values(UserRole).map((role) => {
														if (role === UserRole.ADMIN) {
															return (
																<SelectItem
																	disabled={auth?.user?.user_role !== UserRole.ADMIN}
																	key={role}
																	value={role}
																	className="capitalize">
																	{role}
																</SelectItem>
															);
														}
														return (
															<SelectItem key={role} value={role} className="capitalize">
																{role}
															</SelectItem>
														);
													})}
												</SelectContent>
											</Select>
											<FormMessage />
										</FormItem>
									)}
								/>

								<Button type="submit" className="w-fit self-end">
									Save
								</Button>
							</form>
						</Form>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
};
export default memo(AdminActions);
