import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from 'components/ui/data-table-column-header';
import dayjs from 'dayjs';
import { AdminSchemaType } from './admin.schema';
import AdminActions from './cells/admin-actions';

export const adminUsersColumns: ColumnDef<AdminSchemaType>[] = [
	{
		accessorKey: 'user_id',
		header: 'ID',
	},

	{
		accessorKey: 'user_name',
		header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
		cell: ({ row }) => <span>{row.getValue('user_name')}</span>,
		sortingFn: 'text',
		filterFn: 'includesString',
	},
	{
		accessorKey: 'user_email',
		header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
		cell: ({ row }) => <span>{row.getValue('user_email')}</span>,
		sortingFn: 'text',
		filterFn: 'includesString',
	},
	{
		accessorKey: 'user_role',
		header: ({ column }) => <DataTableColumnHeader column={column} title="Role" />,
		cell: ({ row }) => <span className="capitalize">{row.getValue('user_role')}</span>,
		sortingFn: 'text',
		filterFn: 'includesString',
	},
	{
		accessorKey: 'all_posts',
		header: ({ column }) => <DataTableColumnHeader column={column} title="Posts" />,
		cell: ({ row }) => <span className="inline-block text-center w-full">{row.getValue('all_posts')}</span>,
	},
	{
		accessorKey: 'approved_posts',
		header: ({ column }) => <DataTableColumnHeader column={column} title="Approved" />,
		cell: ({ row }) => <span className="inline-block text-center w-full">{row.getValue('approved_posts')}</span>,
	},
	{
		accessorKey: 'not_approved_posts',
		header: ({ column }) => <DataTableColumnHeader column={column} title="Not approved" />,
		cell: ({ row }) => <span className="inline-block text-center w-full">{row.getValue('not_approved_posts')}</span>,
	},

	{
		accessorKey: 'last_activity',
		header: ({ column }) => <DataTableColumnHeader column={column} title="Last Sign In" />,
		cell: ({ row }) => <span>{dayjs(row.getValue('last_activity')).format('ddd, DD MMMM YYYY hh:mm a')}</span>,
		sortingFn: 'text',
		filterFn: 'includesString',
	},

	{
		accessorKey: 'status',
		header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
		cell: ({ row }) => (
			<AdminActions id={row.getValue('user_id')} refetch={row.original.refetch} role={row.getValue('user_role')} />
		),
		sortingFn: 'text',
		filterFn: 'includesString',
	},
];
