import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toastConfig } from 'utils/toast.config';
import { urlConfig } from 'utils/url-config';
import AWS from 'aws-sdk';

AWS.config.update({
	accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
	secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
});

const s3 = new AWS.S3();

const useDeletePhoto = () => {
	const queryClient = useQueryClient();
	const [isLoading, setIsLoading] = useState(false);

	const deletePhoto = async (id: string, fileName: string, refetch: () => void) => {
		setIsLoading(true);

		const params: AWS.S3.DeleteObjectRequest = {
			Bucket: process.env.REACT_APP_S3_BUCKET_NAME as string,
			Key: fileName,
		};

		try {
			if (fileName) await s3.deleteObject(params).promise();
			await deletePhotoFromAPI(id);
			setIsLoading(false);
			refetch?.();
		} catch (error) {
			console.error('Error deleting object:', error);
			setIsLoading(false);
			throw new Error('Failed to delete photo');
		}
	};

	const deletePhotoFromAPI = async (id: string) => {
		try {
			await fetch(`${urlConfig.API_URL}${urlConfig.api.photos.replace(':id', id)}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			});
			queryClient.invalidateQueries('photo');
			toastConfig.photos.delete.success();
		} catch (error) {
			console.error('Error deleting photo from API:', error);
			toastConfig.photos.delete.error();
		}
	};

	const mutation = useMutation(deletePhotoFromAPI);

	return { isLoading, deletePhoto, mutation };
};

export default useDeletePhoto;
