import { useMutation, useQueryClient } from 'react-query';
import { toastConfig } from 'utils/toast.config';
import { urlConfig } from 'utils/url-config';

const useDeleteUser = (refetch: () => void) => {
	const queryClient = useQueryClient();

	const mutation = useMutation(
		(id: string) =>
			fetch(`${urlConfig.API_URL}${urlConfig.api.deleteUser.replace(':id', id)}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			}),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('posts');
				toastConfig.user.delete.success();
				refetch?.();
			},
			onError: () => {
				toastConfig.user.delete.error();
			},
		}
	);

	return mutation;
};

export default useDeleteUser;
