import Loader from 'components/Loader';
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from 'components/ui/alert-dialog';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { ArrowLeft, Plus, Save, Trash2 } from 'lucide-react';
import { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { isVideoLink } from 'utils/ckeck-video';
import useAddPhoto from './hooks/use-add-photo';
import useDeletePhoto from './hooks/use-delete-photo';
import useGetPhotos from './hooks/use-get-photos';
import useUpdateMedia from './hooks/use-update-media';

type Props = {};

const PostPhotos: React.FC<Props> = ({ ...props }) => {
	const { id } = useParams();
	if (!id) throw new Error('Post id is required');

	const { isLoading: isLoadingPhotos, photos: originalPhotos, refetch } = useGetPhotos(id);
	const { deletePhoto: deletePhotoFromBucket, isLoading: isLoadingDelete } = useDeletePhoto();
	const { mutate: updateMedia, isLoading: isLoadingUpdate } = useUpdateMedia();
	const { mutate: addPhoto, isLoading: isLoadingAdd } = useAddPhoto();

	const [postPhotos, setPostPhotos] = useState<File[]>([]);
	const [postVideos, setPostVideos] = useState<File[]>([]);

	const [photos, setPhotos] = useState<any[]>([]);
	const [draggedPhotoIndex, setDraggedPhotoIndex] = useState<number | null>(null);
	const [hasChanges, setHasChanges] = useState<boolean>(false);

	useEffect(() => {
		setPhotos(originalPhotos);
	}, [originalPhotos]);

	const navigate = useNavigate();

	const handleFileChange = (event: any) => {
		const files = event.target.files;

		for (let i = 0; i < files.length; i++) {
			const file = files[i];

			setPostPhotos((prevPhotos) => [...prevPhotos, file]);
		}
	};

	const handleVideoFileChange = (event: any) => {
		const files = event.target.files;

		const file = files[0];
		setPostVideos((prevPhotos) => [...prevPhotos, file]);
	};

	const handleDragStart = (index: number) => {
		setDraggedPhotoIndex(index);
	};

	const handleDragOver = (index: number) => {
		if (draggedPhotoIndex !== null) {
			const photosCopy = [...photos];
			const draggedPhoto = photosCopy.splice(draggedPhotoIndex, 1)[0];
			photosCopy.splice(index, 0, draggedPhoto);
			setPhotos(photosCopy.map((photo, i) => ({ ...photo, photo_showed_number: i + 1 })));
			setDraggedPhotoIndex(index);
			setHasChanges(true);
		}
	};

	const handleSaveChanges = () => {
		updateMedia({
			post_photos: [...photos],
		});
		setHasChanges(false);
	};

	if (isLoadingPhotos || isLoadingAdd || isLoadingDelete || isLoadingUpdate) {
		return (
			<div className="flex justify-center items-center">
				<Loader />
			</div>
		);
	}

	return (
		<div className="flex flex-col gap-2 p-2">
			<div className="flex items-center justify-between">
				<Badge
					variant="secondary"
					className="w-fit flex items-center gap-1 cursor-pointer h-8 text-sm"
					onClick={() => {
						navigate(-1);
					}}>
					<ArrowLeft className="size-5" />
					Back
				</Badge>

				{hasChanges && (
					<Badge
						onClick={handleSaveChanges}
						variant="success"
						className="w-fit flex items-center gap-1 cursor-pointer h-8 text-sm">
						<Save />
						Save Changes
					</Badge>
				)}

				<AlertDialog>
					<AlertDialogTrigger asChild className="">
						<Badge variant="secondary" className="w-fit flex items-center gap-1 cursor-pointer h-8 text-base" onClick={() => {}}>
							<Plus className="size-5" />
							Add media
						</Badge>
					</AlertDialogTrigger>
					<AlertDialogContent>
						<AlertDialogHeader>
							<AlertDialogTitle>You can add photos to your post</AlertDialogTitle>
						</AlertDialogHeader>
						<div>
							<Label htmlFor="new_post_photos">Photos</Label>
							<Input
								id="new_ost_photos"
								placeholder="file"
								accept="image/*"
								type="file"
								multiple
								onInput={(e) => handleFileChange(e)}
							/>
						</div>
						<div>
							<Label htmlFor="new_post_video">Video</Label>
							<Input
								id="new_ost_video"
								placeholder="file"
								accept="video/*"
								type="file"
								onInput={(e) => handleVideoFileChange(e)}
							/>
						</div>
						<AlertDialogFooter>
							<AlertDialogCancel
								onClick={() => {
									setPhotos([]);
									setPostPhotos([]);
									setPostVideos([]);
								}}>
								Cancel
							</AlertDialogCancel>
							<AlertDialogAction
								onClick={() => {
									addPhoto({
										photoData: { photos: [...postPhotos, ...postVideos] },
										postId: id,
										refetch: () => refetch(),
										setPostPhotos: (photos: File[]) => setPostPhotos(photos),
									});
								}}>
								Add
							</AlertDialogAction>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialog>
			</div>
			<Badge className="w-fit">You can move media around to change the layout</Badge>
			<div className="grid lg:grid-cols-2 gap-2">
				{photos?.map((photoObj: any, index: number) => {
					const { photo_id, photo, photoBucketKey } = photoObj;
					return (
						<div
							key={photo_id}
							className="relative"
							draggable
							onDragStart={() => handleDragStart(index)}
							onDragOver={() => handleDragOver(index)}>
							{photo && isVideoLink(photo as string) ? (
								<video src={photo} autoPlay loop muted controls className="w-full md:h-60 object-cover rounded-md"></video>
							) : (
								<img src={photo} alt="" className="w-full md:h-60 object-cover rounded-md" />
							)}
							<AlertDialog>
								<AlertDialogTrigger asChild className="">
									<Button variant="outline" className="absolute top-2 right-2" size="icon">
										<Trash2 />
									</Button>
								</AlertDialogTrigger>
								<AlertDialogContent>
									<AlertDialogHeader>
										<AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
										<AlertDialogDescription>
											This action cannot be undone. This will permanently delete photo from our servers.
										</AlertDialogDescription>
									</AlertDialogHeader>
									<AlertDialogFooter>
										<AlertDialogCancel>Cancel</AlertDialogCancel>
										<AlertDialogAction
											className="bg-destructive hover:bg-destructive/90"
											onClick={() => {
												deletePhotoFromBucket(photo_id, photoBucketKey, () => refetch());
											}}>
											Delete
										</AlertDialogAction>
									</AlertDialogFooter>
								</AlertDialogContent>
							</AlertDialog>
						</div>
					);
				})}
			</div>
		</div>
	);
};
export default memo(PostPhotos);
