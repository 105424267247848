import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { AuthUser } from 'auth-provider/require-auth';
import Loader from 'components/Loader';
import { Button } from 'components/ui/button';
import { X } from 'lucide-react';
import useGetPosts from 'modules/posts/hooks/use-get-posts';
import React, { useCallback, useEffect, useState } from 'react';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import { useNavigate } from 'react-router';
import { usePostsStore } from 'stores/post-store';
import { cities } from 'utils/cities';
import { cn } from 'utils/cn';
import { DealType, UserRole } from 'utils/types';
import { urlConfig } from 'utils/url-config';

interface Place {
	lat: number;
	lng: number;
}

const containerStyle = {
	width: '100%',
	height: '100svh',
};

type Props = {
	className?: string;
	setMapView?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Map: React.FC<Props> = ({ className, setMapView, ...props }) => {
	const auth = useAuthUser<AuthUser>();
	const isAuth = useIsAuthenticated();

	const path = window.location.pathname;

	const updateCoordinates = usePostsStore((state) => state.updateCoordinates);
	const clearCoordinates = usePostsStore((state) => state.clearCoordinates);
	const postId = usePostsStore((state) => state.postId);
	const setPostId = usePostsStore((state) => state.setPostId);
	const clearPostId = usePostsStore((state) => state.clearPostId);
	const postCity = usePostsStore((state) => state.postCity);
	const postDealType = usePostsStore((state) => state.postDealType);
	const setFromMobilePin = usePostsStore((state) => state.setFromMobilePin);
	const minPrice = usePostsStore((state) => state.postMinPrice);
    const maxPrice = usePostsStore((state) => state.postMaxPrice);
    const metro = usePostsStore((state) => state.postMetroStation);
    const bedrooms = usePostsStore((state) => state.postBedrooms);
    const pets = usePostsStore((state) => state.postPets);
    const rooms = usePostsStore((state) => state.postRooms);
    const tenants = usePostsStore((state) => state.postTenants);
	const minMeters = usePostsStore((state) => state.postMinMeters);
    const maxMeters = usePostsStore((state) => state.postMaxMeters);
	const floor = usePostsStore((state) => state.postFloor)
	const { isLoading, posts } = useGetPosts();

	const [filteredPosts, setFilteredPosts] = useState<any[]>([]);
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
		// libraries: ['places'],
	});

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [map, setMap] = useState<google.maps.Map | null>(null);
	const [selectedPlace, setSelectedPlace] = useState<Place | null>(null);
	const [zoomLevel, setZoomLevel] = useState<number>(14);

	const navigate = useNavigate();

	const handleZoomChange = () => {
		if (!map) return;
		map.addListener('zoom_changed', () => {
			setZoomLevel(map.getZoom() || 0);
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const checkMarkerVisibility = (lat: any, lng: any) => {
		return zoomLevel > 11;
	};

	const onLoad = useCallback(
		function callback(map: any) {
			// const bounds = new window.google.maps.LatLngBounds(center);
			// bounds.extend(center);

			// for (const post of filteredPosts) {
			// 	const postCoordinates = JSON.parse(post.post_coordinates);
			// 	bounds.extend(postCoordinates);
			// }

			// map.fitBounds(bounds);

			setMap(map);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isLoading, filteredPosts, setMap, posts]
	);

	const onUnmount = useCallback(function callback(map: any) {
		setMap(null);
	}, []);

	const handleMapClick = useCallback(
		(event: any) => {
			if (path === urlConfig.pages.addPost || path.includes(urlConfig.pages.updatePost.replace(':id', ''))) {
				setSelectedPlace({
					lat: event.latLng.lat(),
					lng: event.latLng.lng(),
				});
				updateCoordinates(event.latLng.lat(), event.latLng.lng());
			}
		},
		[path, updateCoordinates]
	);

	const handleClearClick = useCallback(() => {
		setSelectedPlace(null);
		clearCoordinates();
	}, [clearCoordinates]);

	const handleMarkerClick = useCallback(
		(post: any) => {
			if (window.innerWidth <= 768) {
				setFromMobilePin(true);
				setMapView?.(false);
			}
			if (postId === post.post_id) {
				clearPostId();
				return;
			}
			setPostId(post.post_id);
			navigate(urlConfig.pages.post.replace(':id', post.post_id));
		},
		[clearPostId, navigate, postId, setFromMobilePin, setMapView, setPostId]
	);

	const MarkerRedIcon: google.maps.Symbol = {
		path: 'M19 53.25C19 53.25 0.625 29.2838 0.625 19.125C0.625 16.712 1.10028 14.3225 2.02371 12.0932C2.94714 9.86383 4.30064 7.83819 6.00691 6.13191C7.71319 4.42564 9.73883 3.07214 11.9682 2.14871C14.1975 1.22528 16.587 0.75 19 0.75C21.413 0.75 23.8025 1.22528 26.0318 2.14871C28.2612 3.07214 30.2868 4.42564 31.9931 6.13191C33.6994 7.83819 35.0529 9.86383 35.9763 12.0932C36.8997 14.3225 37.375 16.712 37.375 19.125C37.375 29.2838 19 53.25 19 53.25ZM19 24.375C20.3924 24.375 21.7277 23.8219 22.7123 22.8373C23.6969 21.8527 24.25 20.5174 24.25 19.125C24.25 17.7326 23.6969 16.3973 22.7123 15.4127C21.7277 14.4281 20.3924 13.875 19 13.875C17.6076 13.875 16.2723 14.4281 15.2877 15.4127C14.3031 16.3973 13.75 17.7326 13.75 19.125C13.75 20.5174 14.3031 21.8527 15.2877 22.8373C16.2723 23.8219 17.6076 24.375 19 24.375Z',
		fillColor: '#AA221F',
		fillOpacity: 1,
		scale: 1,
		...(typeof window !== 'undefined' && window.google?.maps?.Point ? { anchor: new window.google.maps.Point(19, 54) } : {}),
	};

	const MarkerGreenIcon: google.maps.Symbol = {
		path: 'M19 53.25C19 53.25 0.625 29.2838 0.625 19.125C0.625 16.712 1.10028 14.3225 2.02371 12.0932C2.94714 9.86383 4.30064 7.83819 6.00691 6.13191C7.71319 4.42564 9.73883 3.07214 11.9682 2.14871C14.1975 1.22528 16.587 0.75 19 0.75C21.413 0.75 23.8025 1.22528 26.0318 2.14871C28.2612 3.07214 30.2868 4.42564 31.9931 6.13191C33.6994 7.83819 35.0529 9.86383 35.9763 12.0932C36.8997 14.3225 37.375 16.712 37.375 19.125C37.375 29.2838 19 53.25 19 53.25ZM19 24.375C20.3924 24.375 21.7277 23.8219 22.7123 22.8373C23.6969 21.8527 24.25 20.5174 24.25 19.125C24.25 17.7326 23.6969 16.3973 22.7123 15.4127C21.7277 14.4281 20.3924 13.875 19 13.875C17.6076 13.875 16.2723 14.4281 15.2877 15.4127C14.3031 16.3973 13.75 17.7326 13.75 19.125C13.75 20.5174 14.3031 21.8527 15.2877 22.8373C16.2723 23.8219 17.6076 24.375 19 24.375Z',
		fillColor: '#288F1F',
		fillOpacity: 1,
		scale: 1,
		...(typeof window !== 'undefined' && window.google?.maps?.Point ? { anchor: new window.google.maps.Point(19, 54) } : {}),
	};

	const MarkerPurpleIcon: google.maps.Symbol = {
		path: 'M19 53.25C19 53.25 0.625 29.2838 0.625 19.125C0.625 16.712 1.10028 14.3225 2.02371 12.0932C2.94714 9.86383 4.30064 7.83819 6.00691 6.13191C7.71319 4.42564 9.73883 3.07214 11.9682 2.14871C14.1975 1.22528 16.587 0.75 19 0.75C21.413 0.75 23.8025 1.22528 26.0318 2.14871C28.2612 3.07214 30.2868 4.42564 31.9931 6.13191C33.6994 7.83819 35.0529 9.86383 35.9763 12.0932C36.8997 14.3225 37.375 16.712 37.375 19.125C37.375 29.2838 19 53.25 19 53.25ZM19 24.375C20.3924 24.375 21.7277 23.8219 22.7123 22.8373C23.6969 21.8527 24.25 20.5174 24.25 19.125C24.25 17.7326 23.6969 16.3973 22.7123 15.4127C21.7277 14.4281 20.3924 13.875 19 13.875C17.6076 13.875 16.2723 14.4281 15.2877 15.4127C14.3031 16.3973 13.75 17.7326 13.75 19.125C13.75 20.5174 14.3031 21.8527 15.2877 22.8373C16.2723 23.8219 17.6076 24.375 19 24.375Z',
		fillColor: '#851597',
		fillOpacity: 1,
		scale: 1,
		...(typeof window !== 'undefined' && window.google?.maps?.Point ? { anchor: new window.google.maps.Point(19, 54) } : {}),
	};

	const MarkerBlackIcon: google.maps.Symbol = {
		path: 'M19 53.25C19 53.25 0.625 29.2838 0.625 19.125C0.625 16.712 1.10028 14.3225 2.02371 12.0932C2.94714 9.86383 4.30064 7.83819 6.00691 6.13191C7.71319 4.42564 9.73883 3.07214 11.9682 2.14871C14.1975 1.22528 16.587 0.75 19 0.75C21.413 0.75 23.8025 1.22528 26.0318 2.14871C28.2612 3.07214 30.2868 4.42564 31.9931 6.13191C33.6994 7.83819 35.0529 9.86383 35.9763 12.0932C36.8997 14.3225 37.375 16.712 37.375 19.125C37.375 29.2838 19 53.25 19 53.25ZM19 24.375C20.3924 24.375 21.7277 23.8219 22.7123 22.8373C23.6969 21.8527 24.25 20.5174 24.25 19.125C24.25 17.7326 23.6969 16.3973 22.7123 15.4127C21.7277 14.4281 20.3924 13.875 19 13.875C17.6076 13.875 16.2723 14.4281 15.2877 15.4127C14.3031 16.3973 13.75 17.7326 13.75 19.125C13.75 20.5174 14.3031 21.8527 15.2877 22.8373C16.2723 23.8219 17.6076 24.375 19 24.375Z',
		fillColor: '#131415',
		fillOpacity: 1,
		scale: 1,
		...(typeof window !== 'undefined' && window.google?.maps?.Point ? { anchor: new window.google.maps.Point(19, 54) } : {}),
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const MarkerRedCircle: google.maps.Symbol = {
		path: 'M 0, 30 a 30, 30 0 1, 0 60, 0 a 30, 30 0 1, 0 -60, 0',
		fillColor: '#AA221F',
		fillOpacity: 1,
		scale: 1,
		...(typeof window !== 'undefined' && window.google?.maps?.Point ? { anchor: new window.google.maps.Point(19, 54) } : {}),
	};

	useEffect(() => {
		if (postDealType === 'favorites') {
			const safeToPostIdsString = localStorage.getItem('safeToPostIds');
			const safeToPostIds = safeToPostIdsString ? JSON.parse(safeToPostIdsString) : [];
			const filtered = posts.filter((post: any) => safeToPostIds.includes(post.post_id));
			setFilteredPosts(filtered);
		}
	}, [filteredPosts, postDealType, posts]);

	useEffect(() => {
        if (posts) {
            // const metro = 'Liberty Square'; 
            // const maxPrice = 500; 
        
            if (auth && auth?.user?.user_role === UserRole.USER) {
                setFilteredPosts(
                    posts.filter(
                        (post: any) =>
                            post.user.user_id === auth?.user?.user_id &&
                            post.is_approved &&
                            post.post_city === postCity &&
                            (postDealType !== 'none' ? post.post_deal_type === postDealType : post.post_deal_type !== 'none') &&
                            (!minPrice || (post.post_price >= minPrice && (!maxPrice || post.post_price <= maxPrice))) &&
                            (!metro || (post.post_metro_station === metro)) &&
                            (!bedrooms?.toString() || (post.post_bedroom === bedrooms?.toString())) &&
                            (!pets || (post.post_pets === pets)) &&
                            (!rooms?.toString() || (post.post_rooms === rooms?.toString())) &&
                            (!tenants?.toString() || (post.post_tenants_max === tenants?.toString())) &&
                            (!minMeters || (post.post_sq_meters >= minMeters && (!maxMeters || post.post_sq_meters <= maxMeters))) &&
                            (!floor?.toString() || (post.post_floor === floor?.toString()))
                            
                    )
                );
            } else if ((auth && auth?.user?.user_role === UserRole.ADMIN) || auth?.user?.user_role === UserRole.MANAGER) {
                setFilteredPosts(
                    posts.filter(
                        (post: any) =>
                            post.post_city === postCity &&
                            (postDealType !== 'none' ? post.post_deal_type === postDealType : post.post_deal_type !== 'none') &&
                            (!minPrice || (post.post_price >= minPrice && (!maxPrice || post.post_price <= maxPrice))) &&
                            (!metro || (post.post_metro_station === metro)) &&
                            (!bedrooms?.toString() || (post.post_bedroom === bedrooms?.toString())) &&
                            (!pets || (post.post_pets === pets)) &&
                            (!rooms?.toString() || (post.post_rooms === rooms?.toString())) &&
                            (!tenants?.toString() || (post.post_tenants_max === tenants?.toString())) &&
                            (!minMeters || (post.post_sq_meters >= minMeters && (!maxMeters || post.post_sq_meters <= maxMeters))) &&
                            (!floor?.toString() || (post.post_floor === floor?.toString()))
                    )
                );
            } else {
                setFilteredPosts(
                    posts.filter(
                        (post: any) =>
                            post.is_approved &&
                            post.post_city === postCity &&
                            (postDealType !== 'none' ? post.post_deal_type === postDealType : post.post_deal_type !== 'none') &&
                            (!minPrice || (post.post_price >= minPrice && (!maxPrice || post.post_price <= maxPrice))) &&
                            (!metro || (post.post_metro_station === metro)) &&
                            (!bedrooms?.toString() || (post.post_bedroom === bedrooms?.toString())) &&
                            (!pets || (post.post_pets === pets)) &&
                            (!rooms?.toString() || (post.post_rooms === rooms?.toString())) &&
                            (!tenants?.toString() || (post.post_tenants_max === tenants?.toString())) &&
                            (!minMeters || (post.post_sq_meters >= minMeters && (!maxMeters || post.post_sq_meters <= maxMeters))) &&
                            (!floor?.toString() || (post.post_floor === floor?.toString()))
                    )
                );
            }
        }
        
        
        
    }, [auth, auth?.user?.user_id, auth?.user?.user_role, postCity, postDealType, posts, minPrice, maxPrice, metro, bedrooms, pets, rooms, tenants, minMeters, maxMeters, floor]);

	if (isLoading) {
		return (
			<div className="flex justify-center items-center w-0 md:w-3/5 h-screen">
				<Loader />
			</div>
		);
	}

	return (
		<div className={cn('relative', className)}>
			{isLoaded ? (
				<GoogleMap
					mapContainerStyle={containerStyle}
					options={{
						streetViewControl: false,
						mapTypeControl: false,
						fullscreenControl: false,
					}}
					zoom={zoomLevel}
					center={cities.find((city) => city.value === postCity)?.coordinates}
					onLoad={onLoad}
					onZoomChanged={handleZoomChange}
					onUnmount={onUnmount}
					onClick={(e) => {
						if (isAuth) handleMapClick(e);
					}}>
					{filteredPosts?.map((post: any) => {
						const postCoordinates = JSON.parse(post.post_coordinates);
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const postMarker: google.maps.Symbol =
							post.post_deal_type === DealType.RENT
								? MarkerRedIcon
								: post.post_deal_type === DealType.SALE
								? MarkerGreenIcon
								: MarkerPurpleIcon;

						return (
							<Marker
								options={{
									clickable: true,
									icon: postId === post.post_id ? MarkerBlackIcon : MarkerRedIcon,
									zIndex: postId === post.post_id ? 1500 : 900,
								}}
								key={post.post_id}
								position={{
									lat: postCoordinates.lat,
									lng: postCoordinates.lng,
								}}
								// visible={checkMarkerVisibility(postCoordinates.lat, postCoordinates.lng)}
								onClick={() => handleMarkerClick(post)}
							/>
						);
					})}
					{selectedPlace && <Marker position={selectedPlace} icon={MarkerRedIcon} />}

					{/* {zoomLevel < 12 && (
						<Marker
							position={center}
							label={{
								text: String(filteredPosts.length),
								color: 'white',
								fontSize: '30px',
								fontWeight: 'bold',
								className: cn(
									'top-1/2 left-1/2 transform  translate-y-8 absolute leading-[0]',
									filteredPosts.length < 10 && 'translate-x-5',
									filteredPosts.length > 10 && filteredPosts.length < 100 && 'translate-x-3',
									filteredPosts.length > 100 && filteredPosts.length < 1000 && 'translate-x-1'
								),
							}}
							options={{ icon: MarkerRedCircle }}
						/>
					)} */}
				</GoogleMap>
			) : (
				<div className="flex justify-center items-center w-0 md:w-3/5 h-screen">
					<Loader />
				</div>
			)}

			{selectedPlace && (
				<div className="absolute top-2 right-2">
					<Button variant="secondary" className="border" onClick={handleClearClick}>
						<X />
					</Button>
				</div>
			)}
			{/* {postId && (
				<div className="absolute top-2 right-2">
					<Button variant="secondary" className="border" onClick={clearPostId}>
						<X />
					</Button>
				</div>
			)} */}
		</div>
	);
};
export default Map;
