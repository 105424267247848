import { RequireAuth } from 'auth-provider/require-auth';
import { ThrowAuth } from 'auth-provider/throw-auth';
import Admin from 'modules/admin/admin';
import Login from 'modules/auth/login';
import Register from 'modules/auth/register';
import AddPost from 'modules/posts/add-post';
import PostDetails from 'modules/posts/post-details';
import PostPhotos from 'modules/posts/post-photos';
import PostsList from 'modules/posts/posts-list';
import UpdatePost from 'modules/posts/update-post';
import { memo } from 'react';
import { Outlet, useRoutes } from 'react-router-dom';
import { UserRole } from 'utils/types';
import MainPage from './pages/main-page';
import { urlConfig } from './utils/url-config';

interface RoutesProps {}
export const Routes: React.FC<RoutesProps> = memo(() => {
	return useRoutes([
		// auth required
		{
			path: urlConfig.pages.main,
			element: <Outlet />,
			children: [
				{
					path: urlConfig.pages.main,
					element: <MainPage />,
					children: [
						{
							path: urlConfig.pages.main,
							element: <PostsList />,
						},
						{
							path: urlConfig.pages.addPost,
							element: (
								<RequireAuth callbackPath={urlConfig.pages.main}>
									<AddPost />
								</RequireAuth>
							),
						},
						{
							path: urlConfig.pages.updatePost,
							element: (
								<RequireAuth callbackPath={urlConfig.pages.main}>
									<UpdatePost />
								</RequireAuth>
							),
						},
						{
							path: urlConfig.pages.postPhotos,
							element: (
								<RequireAuth callbackPath={urlConfig.pages.main}>
									<PostPhotos />
								</RequireAuth>
							),
						},

						{
							path: urlConfig.pages.post,
							element: <PostDetails />,
						},

						{
							path: urlConfig.pages.postWithAddress,
							element: <PostDetails />,
						},
					],
				},
			],
		},

		{
			path: urlConfig.pages.admin,
			element: (
				<RequireAuth callbackPath={urlConfig.pages.main} allowedRoles={[UserRole.ADMIN, UserRole.MANAGER]}>
					<Admin />
				</RequireAuth>
			),
		},

		// no auth required
		{
			path: urlConfig.pages.login,
			element: (
				<ThrowAuth callbackPath={urlConfig.pages.main}>
					<Login />
				</ThrowAuth>
			),
		},
		{
			path: urlConfig.pages.register,
			element: (
				<ThrowAuth callbackPath={urlConfig.pages.main}>
					<Register />
				</ThrowAuth>
			),
		},

		//
		{
			path: '*',
			// element: <NotFoundPage />,
		},
	]);
});
Routes.displayName = 'Routes';
