import { zodResolver } from '@hookform/resolvers/zod';
import Loader from 'components/Loader';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { toast } from 'components/ui/use-toast';
import { ArrowLeft, X } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { usePostsStore } from 'stores/post-store';
import { cities } from 'utils/cities';
import { urlConfig } from 'utils/url-config';
import { z } from 'zod';
import useCreatePost from './hooks/use-create-post';
import { PostNeighborhoods } from 'utils/types';

const formSchema = z.object({
	post_price: z.string().min(1).max(50),
	post_deposit: z.string().min(1).max(50),
	post_commission: z.string().min(1).max(50),
	post_address: z.string().min(2).max(255),
	post_rooms: z.string().min(1).max(255),
	post_sq_meters: z.string().min(1).max(255),
	post_bedroom: z.string().min(1).max(50),
	post_floor: z.string().min(1).max(50),
	post_tenants_max: z.string().min(1).max(50),
	post_pets: z.string().min(1).max(50),
	post_deal_type: z.string().min(1).max(50),
	post_phone_number: z.string().min(1).max(50),
	post_neighborhood: z.nativeEnum(PostNeighborhoods).optional(),
	post_metro_station: z.string(),
	post_city: z.string(),
});

const AddPost = () => {
	const clearCoordinates = usePostsStore((state) => state.clearCoordinates);
	const coordinates = usePostsStore((state) => state.coordinates);

	const [postPhotos, setPostPhotos] = useState<File[]>([]);
	const [postVideos, setPostVideos] = useState<File[]>([]);
	const [words, setWords] = useState<string[]>([]);
	const [inputValue, setInputValue] = useState<string>('');

	const handleInputChange = (e: any) => {
		setInputValue(e.target.value);
	};

	const handleAddWord = () => {
		if (inputValue.trim() !== '') {
			setWords([...words, inputValue.trim()]);
			setInputValue('');
		}
	};

	const handleRemoveWord = (indexToRemove: number) => {
		setWords(words.filter((word, index) => index !== indexToRemove));
	};

	const { mutate, isLoading } = useCreatePost();

	const navigate = useNavigate();

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			post_price: '',
			post_deposit: '',
			post_commission: '',
			post_address: '',
			post_rooms: '',
			post_sq_meters: '',
			post_bedroom: '',
			post_metro_station: '',
			post_floor: '',
			post_pets: '',
			post_deal_type: '',
			post_tenants_max: '',
			post_phone_number: '',
			post_city: '',
		},
	});

	function onSubmit(values: z.infer<typeof formSchema>) {
		if (postPhotos.length === 0) {
			toast({
				title: 'Failed to create post',
				description: 'Please add at least one photo',
				variant: 'warning',
			});
			return;
		}
		if (words.length === 0) {
			toast({
				title: 'Failed to create post',
				description: 'Please add at least one tag',
				variant: 'warning',
			});
			return;
		}
		if (coordinates.lat === 0 || coordinates.lng === 0) {
			toast({
				title: 'Failed to create post',
				description: 'Please select a location on map',
				variant: 'warning',
			});
			return;
		}

		mutate({
			...values,
			post_photos: [...postPhotos, ...postVideos],
			post_coordinates: JSON.stringify({ ...coordinates }),
			post_tags: JSON.stringify({ tags: words }),
		});

		form.reset();
		setPostPhotos([]);
		clearCoordinates();
		setWords([]);
		// navigate(urlConfig.pages.main);
	}

	const handleFileChange = (event: any) => {
		const files = event.target.files;

		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			setPostPhotos((prevPhotos) => [...prevPhotos, file]);
		}
	};

	const handleVideoFileChange = (event: any) => {
		const files = event.target.files;

		const file = files[0];
		setPostVideos((prevPhotos) => [...prevPhotos, file]);
	};

	if (isLoading) {
		return (
			<div className="flex justify-center items-center">
				<Loader />
			</div>
		);
	}

	return (
		<div className="p-2 overflow-y-auto flex flex-col gap-1 pb-14">
			<Badge
				variant="secondary"
				className="w-fit flex items-center gap-1 cursor-pointer h-8 text-sm"
				onClick={() => navigate(urlConfig.pages.main)}>
				<ArrowLeft className="size-5" />
				Back to listings
			</Badge>
			<Badge variant="outline" className="w-fit break-words bg-yellow-300 border-yellow-500">
				Do not forget to select the location on the map
			</Badge>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-3">
					<div>
						<Label htmlFor="post_photos">Photos</Label>
						<Input
							id="post_photos"
							placeholder="file"
							accept="image/*"
							type="file"
							multiple
							onInput={(e) => handleFileChange(e)}
						/>
					</div>
					<div>
						<Label htmlFor="post_video">Video</Label>
						<Input
							id="post_video"
							placeholder="file"
							accept="video/*"
							type="file"
							multiple
							onInput={(e) => handleVideoFileChange(e)}
						/>
					</div>
					<div className="flex gap-1">
						<FormField
							control={form.control}
							name="post_price"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Price $</FormLabel>
									<FormControl>
										<Input placeholder="500" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="post_deposit"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Deposit $</FormLabel>
									<FormControl>
										<Input placeholder="500" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="post_commission"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Commission %</FormLabel>
									<FormControl>
										<Input placeholder="10" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
					</div>
					<div className="flex gap-1">
						<FormField
							control={form.control}
							name="post_rooms"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Rooms</FormLabel>
									<FormControl>
										<Input placeholder="2" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="post_sq_meters"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Sq. m</FormLabel>
									<FormControl>
										<Input placeholder="31" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="post_bedroom"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Bedroom</FormLabel>
									<FormControl>
										<Input placeholder="1" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
					</div>
					<div className="flex gap-1">
						<FormField
							control={form.control}
							name="post_floor"
							render={({ field }) => (
								<FormItem className="w-1/2">
									<FormLabel>Floor</FormLabel>
									<FormControl>
										<Select onValueChange={field.onChange} defaultValue={field.value}>
											<FormControl>
												<SelectTrigger>
													<SelectValue placeholder="Select a floor number" />
												</SelectTrigger>
											</FormControl>
											<SelectContent>
												{Array.from({ length: 20 }, (_, index) => index + 1).map((value) => {
													return (
														<SelectItem key={value} value={String(value)}>
															{value}
														</SelectItem>
													);
												})}
											</SelectContent>
										</Select>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="post_tenants_max"
							render={({ field }) => (
								<FormItem className="w-1/2">
									<FormLabel>Tenants</FormLabel>
									<FormControl>
										<Select onValueChange={field.onChange} defaultValue={field.value}>
											<FormControl>
												<SelectTrigger>
													<SelectValue placeholder="Select a max tenants number" />
												</SelectTrigger>
											</FormControl>
											<SelectContent>
												{Array.from({ length: 6 }, (_, index) => index + 1).map((value) => {
													return (
														<SelectItem key={value} value={String(value)}>
															{value}
														</SelectItem>
													);
												})}
											</SelectContent>
										</Select>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
					</div>
					<FormField
						control={form.control}
						name="post_deal_type"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Deal type</FormLabel>
								<FormControl>
									<Select onValueChange={field.onChange} defaultValue={field.value}>
										<FormControl>
											<SelectTrigger>
												<SelectValue placeholder="Select a deal type" />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											<SelectItem value="sale">Sale</SelectItem>
											<SelectItem value="rent">Rent</SelectItem>
											<SelectItem value="daily">Short term</SelectItem>
										</SelectContent>
									</Select>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="post_pets"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Pets</FormLabel>
								<FormControl>
									<Select onValueChange={field.onChange} defaultValue={field.value}>
										<FormControl>
											<SelectTrigger>
												<SelectValue placeholder="Select a pets property" />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											<SelectItem value="by-agreement">by-agreement</SelectItem>
											<SelectItem value="no-pets">no-pets</SelectItem>
										</SelectContent>
									</Select>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="post_address"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Address</FormLabel>
								<FormControl>
									<Input placeholder="Saburtalo, 17 Sulkhan Tsintsadze St" {...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="post_neighborhood"
						render={({ field }) => (
							<FormItem className="w-full">
								<FormLabel>Neighborhood(optional)</FormLabel>
								<FormControl>
									<Select onValueChange={field.onChange} defaultValue={field.value}>
										<FormControl>
											<SelectTrigger>
												<SelectValue className="capitalize" placeholder="Select a neighborhood" />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											{Object.values(PostNeighborhoods).map((value) => {
												return (
													<SelectItem key={value} value={value} className="capitalize">
														{value}
													</SelectItem>
												);
											})}
										</SelectContent>
									</Select>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="post_city"
						render={({ field }) => (
							<FormItem>
								<FormLabel>City</FormLabel>
								<Select onValueChange={field.onChange} defaultValue={field.value}>
									<FormControl>
										<SelectTrigger>
											<SelectValue placeholder="Select city" />
										</SelectTrigger>
									</FormControl>
									<SelectContent>
										{cities.map((city) => {
											return (
												<SelectItem key={city.value} value={city.value}>
													{city.label}
												</SelectItem>
											);
										})}
									</SelectContent>
								</Select>

								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="post_phone_number"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Phone number</FormLabel>
								<FormControl>
									<Input placeholder="+374 99 999 999" type="tel" {...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<div className="flex flex-col gap-2">
						<Label htmlFor="post_tags">Tags</Label>
						<div className="flex">
							<Input id="post_tags" type="text" value={inputValue} onChange={handleInputChange} placeholder="Enter a tag" />
							<Button type="button" onClick={handleAddWord}>
								Add
							</Button>
						</div>
						<div className="flex gap-1 flex-wrap">
							{words.map((word, index) => (
								<Badge variant="outline" key={index} className="flex gap-1 items-center">
									<span className="text-sm">{word}</span>
									<X className="size-4 cursor-pointer" onClick={() => handleRemoveWord(index)} />
								</Badge>
							))}
						</div>
					</div>
					<FormField
						control={form.control}
						name="post_metro_station"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Metro station(optional)</FormLabel>
								<FormControl>
									<Input placeholder="Technical University" {...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<div className="flex flex-col gap-2">
						<Button type="submit" className="w-full">
							Submit
						</Button>
					</div>
				</form>
			</Form>
		</div>
	);
};
export default AddPost;
