import { AuthUser } from 'auth-provider/require-auth';
import Loader from 'components/Loader';
import { Telegram } from 'components/Telegram';
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from 'components/ui/alert-dialog';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import dayjs from 'dayjs';
import { ArrowLeft } from 'lucide-react';
import { memo } from 'react';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import { useNavigate, useParams } from 'react-router';
import { usePostsStore } from 'stores/post-store';
import { cn } from 'utils/cn';
import { toastConfig } from 'utils/toast.config';
import { UserRole } from 'utils/types';
import { urlConfig } from 'utils/url-config';
import useApprovePost from './hooks/use-approve-post';
import useDeletePost from './hooks/use-delete-post';
import useGetPost from './hooks/use-get-post';
import Post from './post';

dayjs().format();

const PostDetails = () => {
	const { id } = useParams();
	if (!id) throw new Error('Post id is required');

	const isAuth = useIsAuthenticated();
	const auth = useAuthUser<AuthUser>();

	const { isLoading, post, refetch } = useGetPost(id);
	const { mutate } = useDeletePost();
	const { mutate: updatePost, isLoading: isLoadingUpdate } = useApprovePost();

	const clearPostId = usePostsStore((state) => state.clearPostId);
	const fromMobilePin = usePostsStore((state) => state.fromMobilePin);
	const navigate = useNavigate();

	const onToggleApprove = () => {
		updatePost(
			{ post_id: id, is_approved: !post?.is_approved },
			{
				onSuccess: () => {
					refetch();
					toastConfig.post.approve.success(!post?.is_approved ? 'approved' : 'disapproved');
				},
				onError: () => {
					toastConfig.post.approve.error();
				},
			}
		);
	};

	if (isLoading || isLoadingUpdate) {
		return (
			<div className="flex justify-center items-center">
				<Loader />
			</div>
		);
	}

	return (
		<div className="flex flex-col gap-2 p-2">
			{fromMobilePin ? (
				<Badge
					variant="secondary"
					className="w-fit flex items-center gap-1 cursor-pointer h-8 text-sm"
					onClick={() => {
						navigate(urlConfig.pages.main);
					}}>
					<ArrowLeft className="size-5" />
					Back
				</Badge>
			) : (
				<Badge
					variant="secondary"
					className="w-fit flex items-center gap-1 cursor-pointer h-8 text-sm"
					onClick={() => {
						navigate(urlConfig.pages.main);
						clearPostId();
					}}>
					<ArrowLeft className="size-5" />
					Back to listings
				</Badge>
			)}

			{isAuth && (auth?.user?.user_role === UserRole.ADMIN || auth?.user?.user_role === UserRole.MANAGER) && (
				<>
					<Separator className="w-[95%]" />
					<div className="flex gap-2 text-sm flex-wrap">
						<Badge className="w-fit flex gap-1">
							Created at: <span className="font-semibold">{dayjs(post.created_at).format('ddd, DD MMMM YYYY')}</span>
						</Badge>
						<Badge className="w-fit flex gap-1">
							Created by: <span className="font-semibold">{post.user.user_name}</span>
						</Badge>
						<Badge variant={post.is_approved ? 'default' : 'destructive'} className="w-fit flex gap-1">
							Approved: <span className="font-semibold">{post.is_approved ? 'Yes' : 'No'}</span>
						</Badge>
					</div>
					<Separator className="w-[95%]" />
				</>
			)}
			<div>
				<Post post={post} sliderClasses="w-full h-72" postClasses="flex-col" />
				{isAuth && (
					<div className="flex gap-2 text-sm flex-wrap">
						<AlertDialog>
							<AlertDialogTrigger asChild className="">
								<Button variant="destructive" className="w-fit">
									Delete post
								</Button>
							</AlertDialogTrigger>
							<AlertDialogContent>
								<AlertDialogHeader>
									<AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
									<AlertDialogDescription>
										This action cannot be undone. This will permanently delete post from our servers.
									</AlertDialogDescription>
								</AlertDialogHeader>
								<AlertDialogFooter>
									<AlertDialogCancel>Cancel</AlertDialogCancel>
									<AlertDialogAction className="bg-destructive hover:bg-destructive/90" onClick={() => mutate(post.post_id)}>
										Delete
									</AlertDialogAction>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialog>
						<Button
							variant="outline"
							className="w-fit"
							onClick={() => navigate(urlConfig.pages.updatePost.replace(':id', post.post_id))}>
							Update post
						</Button>
						<Button
							variant="outline"
							className="w-fit"
							onClick={() => navigate(urlConfig.pages.postPhotos.replace(':id', post.post_id))}>
							Post media
						</Button>
						{(auth?.user?.user_role === UserRole.ADMIN || auth?.user?.user_role === UserRole.MANAGER) && (
							<AlertDialog>
								<AlertDialogTrigger asChild className="">
									<Button variant="outline" className="w-fit">
										{post.is_approved ? 'Disapprove' : 'Approve'} post
									</Button>
								</AlertDialogTrigger>
								<AlertDialogContent>
									<AlertDialogHeader>
										<AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
										<AlertDialogDescription>
											This action will {post.is_approved ? 'Disapprove' : 'approve'} post.
										</AlertDialogDescription>
									</AlertDialogHeader>
									<AlertDialogFooter>
										<AlertDialogCancel>Cancel</AlertDialogCancel>
										<AlertDialogAction onClick={() => onToggleApprove()}>
											{post.is_approved ? 'Disapprove' : 'Approve'}
										</AlertDialogAction>
									</AlertDialogFooter>
								</AlertDialogContent>
							</AlertDialog>
						)}
					</div>
				)}
			</div>
			<div className="flex gap-2 flex-wrap flex-col-reverse md:flex-row w-full">
				<a
					href={`tel:${post.post_phone_number}`}
					className={cn(
						'bg-primary text-primary-foreground hover:bg-primary/90 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 px-4 py-2',
						'flex items-center gap-1 md:w-fit'
					)}>
					<span>Check Availability</span>
				</a>
				<Button
					variant="outline"
					className="md:w-fit items-center gap-1"
					onClick={() => {
						window.open(`https://t.me/${post.post_phone_number}`, '_blank');
						// window.open('https://google.com', '_blank');
					}}>
					<Telegram className="size-6" />
					Write on Telegram
				</Button>
			</div>
		</div>
	);
};
export default memo(PostDetails);
