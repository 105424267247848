import { memo } from 'react';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import { Navigate } from 'react-router-dom';
import { UserRole } from '../utils/types';
import { urlConfig } from '../utils/url-config';

interface RequireAuthProps {
	callbackPath?: string;
	allowedRoles?: UserRole[];
}

export interface AuthUser {
	access_token: string;
	user: { user_id: string; user_name: string; user_email: string; user_role: UserRole; is_verified: boolean };
}

export const RequireAuth: React.FC<React.PropsWithChildren<RequireAuthProps>> = memo(
	({ callbackPath = urlConfig.pages.login, allowedRoles = [UserRole.ADMIN, UserRole.MANAGER, UserRole.USER], children }) => {
		const isAuth = useIsAuthenticated();
		const auth = useAuthUser<AuthUser>();

		if (!isAuth) {
			return <Navigate to={callbackPath} replace />;
		}
		if (!allowedRoles.includes(auth?.user?.user_role as UserRole)) {
			return <Navigate to={callbackPath} replace />;
		}

		return <>{children}</>;
	}
);
RequireAuth.displayName = 'RequireAuth';
