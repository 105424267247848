import { useQuery } from 'react-query';
import { urlConfig } from 'utils/url-config';

const useGetPhotos = (id: string | undefined) => {
	const { isLoading, isError, data, error, refetch } = useQuery(id as string, async () => {
		const response = await fetch(`${urlConfig.API_URL}${urlConfig.api.photos.replace(':id', id as string)}`, {
			headers: {
			    'Content-Type': 'application/json',
			    Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
			cache: 'no-store',
		});

		if (!response.ok) {
			throw new Error('Failed to fetch posts');
		}

		return response.json();
	});

	return {
		isLoading,
		isError,
		photos: data,
		error,
		refetch,
	};
};

export default useGetPhotos;
