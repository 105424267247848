export enum UserRole {
	USER = 'user',
	ADMIN = 'admin',
	MANAGER = 'manager',
}

export enum DealType {
	RENT = 'rent',
	SALE = 'sale',
	DAILY = 'daily',
}

export enum PostNeighborhoods {
	VAKE = 'vake',
	VERA = 'vera',
	SABURTALO = 'saburtalo',
	MARJANASHVILI = 'marjanashvili',
	ORTACHALA = 'ortachala',
}

export const defaultIconProps = {
	strokeWidth: 1.5,
} as const;

export type User = {
	user_id: string;
	user_name: string;
	user_email: string;
	user_password: string;
	user_role: UserRole;
	is_verified: boolean;
	verification_token: string;
	is_activated: boolean;
	last_activity: string;
	user_posts: {
		all_posts: number;
		approved_posts: number;
		not_approved_posts: number;
	};
};
