import { ChevronLeft, ChevronRight, ImageOff } from 'lucide-react';
import React, { useState, useRef, useEffect } from 'react';
import { isVideoLink } from 'utils/ckeck-video';
import { cn } from 'utils/cn';
import { Badge } from './ui/badge';
import { Button } from './ui/button';
import { Img } from 'react-image';
import { useInView } from 'react-intersection-observer';

const Slider = ({ data, className }: { data: { photo_id: React.Key | null | undefined; photo: string | undefined }[]; className?: string }) => {
    const [index, setIndex] = useState<number>(0);
    const [dragging, setDragging] = useState<boolean>(false);
    const [startX, setStartX] = useState<number>(0);
	const [hasScrolled, setHasScrolled] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [scroll, setScroll] = useState(false);
    const { ref, inView } = useInView();
    const prevSlide = () => {
        setIndex((prevIndex) => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
    };

    const nextSlide = () => {
        setIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
    };

    const handleTouchStart = (event: React.TouchEvent<HTMLVideoElement | HTMLImageElement>) => {
        setDragging(true);
        setStartX(event.touches[0].pageX);
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLVideoElement | HTMLImageElement>) => {
        if (!dragging) return;
        const x = event.touches[0].pageX;
        const deltaX = startX - x;
        if (deltaX > 5) {
            nextSlide();
            setDragging(false);
        } else if (deltaX < -5) {
            prevSlide();
            setDragging(false);
        }
    };

    const handleTouchEnd = () => {
        setDragging(false);
    };

    
    useEffect(() => {
        const handleScroll = () => {
           
            const container = containerRef.current;
            if (!container) return;
    
            const rect = container.getBoundingClientRect();
            const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
    
            if (isVisible) {
                const elements = container.querySelectorAll('.slider-item');
                elements.forEach((element: Element) => {
                    const rect = element.getBoundingClientRect();
                    const isVisible = rect.top < container.clientHeight && rect.bottom >= 0;
                    if (isVisible) {
                        const img = element.querySelector('img');
                        if (img && !img.src) {
                            img.src = img.getAttribute('src') || '';
                        }
                    } else {
                        const img = element.querySelector('img');
                        if (img && img.src) {
                            img.removeAttribute('src');
                        }
                    }
                });
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [inView]); 

    return (
        <section className={cn('w-56 h-56 rounded-lg', className)}>
            <div ref={ref} className="my-0 mx-auto text-center relative flex overflow-hidden h-full">
                {inView && data.map((item: { photo_id: React.Key | null | undefined; photo: string | undefined }, personIndex: number) => (
                    <article key={item.photo_id} className={`slider-item absolute top-0 left-0 w-full h-full transition-transform z-[49] ${personIndex === index ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}>
                        {inView && item.photo && isVideoLink(item.photo as string) ? (
                            <video
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                                onTouchCancel={handleTouchEnd}
                                src={item.photo}
                                autoPlay
                                loop
                                muted
                                controls
                                className="w-full h-full object-cover rounded-lg"
                            />
                        ) : (
                            <img
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                                onTouchCancel={handleTouchEnd}
                                src={item.photo}
                                alt=""
                                className="w-full h-full object-cover rounded-lg"
                                decoding="async"
                                loading="lazy"
                            />
                        )}
                    </article>
                ))}
                {data.length > 1 && (
                    <>
                        <Button
                            className="absolute z-50 left-1 top-1/2 transform -translate-y-1/2 rounded-full bg-white text-black border-2 hover:bg-white/90 hidden md:inline-flex"
                            size="icon"
                            onClick={prevSlide}>
                            <ChevronLeft />
                        </Button>
                        <Button
                            className="absolute z-50 right-1 top-1/2 transform -translate-y-1/2 rounded-full bg-white text-black border-2 hover:bg-white/90 hidden md:inline-flex"
                            size="icon"
                            onClick={nextSlide}>
                            <ChevronRight />
                        </Button>
                        <Badge className="absolute right-1/2 transform translate-x-1/2 bottom-2 z-[50]">
                            {index + 1} of {data.length}
                        </Badge>
                    </>
                )}
            </div>
        </section>
    );
};

export default Slider;
