import { useMutation } from 'react-query';
import { toastConfig } from 'utils/toast.config';
import { urlConfig } from 'utils/url-config';

const useUpdatePost = () => {
	const updatePost = async (postData: any) => {
		const postId = postData.post_id; 
		const postDataWithoutPhotos = { ...postData };
		delete postDataWithoutPhotos.post_photos;

		const response = await fetch(`${urlConfig.API_URL}${urlConfig.api.updatePost}/${postId}`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
			body: JSON.stringify(postDataWithoutPhotos),
		});

		if (!response.ok) {
			toastConfig.post.update.error();
			throw new Error('Failed to update post');
		}

		const responseData = await response.json();

		toastConfig.post.update.success();
		return responseData;
	};

	return useMutation(updatePost);
};

export default useUpdatePost;
