import { useMutation } from 'react-query';
import { toastConfig } from 'utils/toast.config';
import { urlConfig } from 'utils/url-config';

const useUpdateUser = (refetch: () => void) => {
	const updateUser = async (userData: any) => {
		const userId = userData.user_id;

		const response = await fetch(`${urlConfig.API_URL}${urlConfig.api.updateUser.replace(':id', userId)}`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
			body: JSON.stringify(userData),
		});

		if (!response.ok) {
			toastConfig.user.update.error();
			throw new Error('Failed to update post');
		}

		const responseData = await response.json();

		toastConfig.user.update.success();
		refetch?.();
		return responseData;
	};

	return useMutation(updateUser);
};

export default useUpdateUser;
