import { AuthUser } from 'auth-provider/require-auth';
import Loader from 'components/Loader';
import { Button } from 'components/ui/button';
import { DataTable } from 'components/ui/data-table';
import { DataTableInputFilter } from 'components/ui/data-table-input-filter';
import { DataTableToolbar } from 'components/ui/data-table-toolbar';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { ChevronLeft, RefreshCcw } from 'lucide-react';
import { useEffect, useState } from 'react';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useNavigate } from 'react-router';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { genColor } from 'utils/gen-color';
import { User } from 'utils/types';
import useGetAdminUsers from './hooks/use-get-admin-users';
import { adminUsersColumns } from './table/admin-columns';

interface ChartData {
	name: string;
	value: number;
}

const Admin = () => {
	const auth = useAuthUser<AuthUser>();

	const [chartData, setChartData] = useState<ChartData[]>([]);

	const { isLoading, users, refetch } = useGetAdminUsers(auth?.user?.user_id);

	const navigate = useNavigate();

	useEffect(() => {
		if (users) {
			setChartData(
				users.map((user: User) => {
					return {
						name: user.user_name,
						value: user.user_posts.all_posts,
					};
				})
			);
		}
	}, [users]);

	if (isLoading) {
		return (
			<div className="flex justify-center items-center">
				<Loader />
			</div>
		);
	}

	return (
		<div className="animate-in fade-in-0 container transition-all duration-300 flex flex-col gap-2 py-4">
			<div className="flex items-center justify-between">
				<Button variant="secondary" onClick={() => navigate(-1)}>
					<ChevronLeft />
					Back
				</Button>
				<Button variant="outline" onClick={() => refetch()}>
					<RefreshCcw />
				</Button>
			</div>
			<Tabs defaultValue="chart" className="w-full">
				<TabsList className="grid w-full grid-cols-2">
					<TabsTrigger value="chart">Chart</TabsTrigger>
					<TabsTrigger value="table">Table</TabsTrigger>
				</TabsList>
				<TabsContent value="chart">
					<div className="w-full h-80">
						<ResponsiveContainer width="100%" height="100%">
							<PieChart width={400} height={400}>
								<Pie
									dataKey="value"
									isAnimationActive={false}
									data={chartData}
									cx="50%"
									cy="50%"
									outerRadius={80}
									fill="#8884d8"
									label>
									{chartData?.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={genColor()} />
									))}
								</Pie>
								<Tooltip />
							</PieChart>
						</ResponsiveContainer>
					</div>
				</TabsContent>
				<TabsContent value="table" className="w-full overflow-x-auto">
					<div className="flex flex-col gap-2 lg:flex-row">
						<DataTable
							columns={adminUsersColumns}
							data={
								users?.map((user: User) => ({
									...user,
									refetch: refetch,
									all_posts: user.user_posts.all_posts,
									approved_posts: user.user_posts.approved_posts,
									not_approved_posts: user.user_posts.not_approved_posts,
								})) ?? []
							}
							pagination
							initialHidden={['user_id']}
							className="w-full overflow-x-auto">
							<DataTableToolbar>
								<DataTableInputFilter column="user_name" label="Name" />
								<DataTableInputFilter column="user_email" label="Email" />
								<DataTableInputFilter column="user_role" label="Role" />
							</DataTableToolbar>
						</DataTable>
					</div>
				</TabsContent>
			</Tabs>
		</div>
	);
};
export default Admin;
