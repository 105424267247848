import { useQuery } from 'react-query';
import { urlConfig } from 'utils/url-config';

const useGetPosts = () => {
	const { isLoading, isError, data, error, refetch } = useQuery('posts', async () => {
		const response = await fetch(`${urlConfig.API_URL}${urlConfig.api.getPosts}`, {
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (!response.ok) {
			throw new Error('Failed to fetch posts');
		}

		return response.json();
	});

	return {
		isLoading,
		isError,
		posts: data,
		error,
		refetch,
	};
};

export default useGetPosts;
