import AWS from 'aws-sdk';
import { useMutation } from 'react-query';
import { toastConfig } from 'utils/toast.config';
import { urlConfig } from 'utils/url-config';

AWS.config.update({
	accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
	secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
});

const s3 = new AWS.S3();

const useCreatePost = () => {
	const createPost = async (postData: any) => {
		const postDataWithoutPhotos = { ...postData };
		delete postDataWithoutPhotos.post_photos;

		const response = await fetch(`${urlConfig.API_URL}${urlConfig.api.createPost}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
			body: JSON.stringify(postDataWithoutPhotos),
		});

		if (!response.ok) {
			toastConfig.post.create.error();
			throw new Error('Failed to create post');
		}

		const responseData = await response.json();

		const uploadFile = (params: AWS.S3.PutObjectRequest, index: number) => {
			return new Promise((resolve, reject) => {
				s3.upload(params, async (err, data) => {
					if (err) {
						reject(err);
					} else {
						try {
							const photoResponse = await fetch(`${urlConfig.API_URL}${urlConfig.api.createPhoto}`, {
								method: 'POST',
								headers: {
									'Content-Type': 'application/json',
									Authorization: 'Bearer ' + localStorage.getItem('token'),
								},
								body: JSON.stringify({
									photo: data?.Location,
									postId: responseData.post_id,
									photoBucketKey: data?.Key,
									photo_showed_number: index,
								}),
							});

							if (!photoResponse.ok) {
								toastConfig.post.create.error();
								throw new Error('Failed to upload photo');
							}
							resolve(data);
						} catch (error) {
							console.error('Error uploading photo to API:', error);
							reject(error);
						}
					}
				});
			});
		};

		for (const [index, photo] of postData.post_photos.entries()) {
			const params: AWS.S3.Types.PutObjectRequest = {
				Bucket: process.env.REACT_APP_S3_BUCKET_NAME as string,
				Key: new Date().getTime() + photo.name,
				Body: photo,
			};

			await uploadFile(params, index + 1);
		}

		toastConfig.post.create.success();
		return responseData;
	};

	return useMutation(createPost);
};

export default useCreatePost;
