import { memo } from 'react';
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';

const store = createStore({
	authName: '_auth',
	authType: 'cookie',
	cookieDomain: window.location.hostname,
	cookieSecure: window.location.protocol === 'https:',
});

interface AuthProviderProps {}

export const AuthKitProvider: React.FC<React.PropsWithChildren<AuthProviderProps>> = memo(({ children }) => {
	return <AuthProvider store={store}>{children}</AuthProvider>;
});
AuthKitProvider.displayName = 'AuthKitProvider';
