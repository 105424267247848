import { InlineCode } from 'components/ui/code';
import { toast } from 'components/ui/use-toast';

export const toastConfig = {
	login: {
		success: (name: string) =>
			toast({
				title: 'Login successful',
				description: (
					<>
						Welcome back, <InlineCode>{name}</InlineCode>
					</>
				),
				variant: 'success',
			}),
		error: () =>
			toast({
				title: 'Failed to login',
				description: 'Please try again later',
				variant: 'destructive',
			}),
	},

	register: {
		success: (name: string) =>
			toast({
				title: 'Register successful',
				description: (
					<>
						Welcome, <InlineCode>{name}</InlineCode>
					</>
				),
				variant: 'success',
			}),
		error: () =>
			toast({
				title: 'Failed to register',
				description: 'Please try again later',
				variant: 'destructive',
			}),
	},

	reset: {
		resetWithEmail: (email: string) =>
			toast({
				title: 'First step is done!',
				description: (
					<>
						Please check your email <InlineCode>{email}</InlineCode>, we have sent you further instructions.
					</>
				),
				variant: 'success',
			}),
		error: () =>
			toast({
				title: 'Failed to reset',
				description: 'Please try again later',
				variant: 'destructive',
			}),
	},

	photos: {
		create: {
			success: () =>
				toast({
					title: 'Photo added successfully',
					variant: 'success',
				}),

			error: () =>
				toast({
					title: 'Failed to add photo',
					description: 'Please try again later',
					variant: 'destructive',
				}),
		},
		update: {
			success: () =>
				toast({
					title: 'Media updated successfully',
					variant: 'success',
				}),

			error: () =>
				toast({
					title: 'Failed to update media',
					description: 'Please try again later',
					variant: 'destructive',
				}),
		},

		delete: {
			success: () =>
				toast({
					title: 'Photo deleted',
					variant: 'destructive',
				}),
			error: () =>
				toast({
					title: 'Failed to delete photo',
					description: 'Please try again later',
					variant: 'destructive',
				}),
		},
	},
	post: {
		create: {
			success: () =>
				toast({
					title: 'Post sent for approvement',
					variant: 'success',
				}),

			error: () =>
				toast({
					title: 'Failed to create post',
					description: 'Please try again later',
					variant: 'destructive',
				}),
		},
		update: {
			success: () =>
				toast({
					title: 'Post updated successfully',
					variant: 'success',
				}),
			error: () =>
				toast({
					title: 'Failed to update post',
					description: 'Please try again later',
					variant: 'destructive',
				}),
		},
		approve: {
			success: (action: string) =>
				toast({
					title: `Post ${action} successfully`,
					variant: 'success',
				}),
			error: () =>
				toast({
					title: 'Failed to approve post',
					description: 'Please try again later',
					variant: 'destructive',
				}),
		},
		delete: {
			success: () =>
				toast({
					title: 'Post deleted',
					variant: 'destructive',
				}),
			error: () =>
				toast({
					title: 'Failed to delete post',
					description: 'Please try again later',
					variant: 'destructive',
				}),
		},
	},

	user: {
		update: {
			success: () =>
				toast({
					title: 'User updated successfully',
					variant: 'success',
				}),
			error: () =>
				toast({
					title: 'Failed to update user',
					description: 'Please try again later',
					variant: 'destructive',
				}),
		},

		delete: {
			success: () =>
				toast({
					title: 'User deleted successfully',
					variant: 'destructive',
				}),
			error: () =>
				toast({
					title: 'Failed to delete user',
					description: 'Please try again later',
					variant: 'destructive',
				}),
		},
	},
	favouritePost: {
		add: {
			success: () =>
				toast({
					title: 'Post added to favourites successfully',
					variant: 'success',
				}),
		},

		delete: {
			error: () =>
				toast({
					title: 'Post removed from favourites',
					variant: 'destructive',
				}),
		},
	},

	unknownError: () =>
		toast({
			title: 'Unknown error',
			description: 'Please try again later',
			variant: 'destructive',
		}),
};
