export const urlConfig = {
	pages: {
		main: '/',
		login: '/login',
		register: '/register',
		addPost: '/add-post',
		post: '/post/:id',
		postWithAddress: '/post/:id/:address',
		updatePost: '/post/update/:id',
		postPhotos: '/post/photos/:id',
		admin: '/admin',
	},
	api: {
		login: '/auth/login',
		register: '/auth/register',
		getPosts: '/posts',
		post: '/posts/:id',
		createPost: '/posts/create',
		updatePost: '/posts/update',
		approvePost: '/posts/approve',
		createPhoto: '/posts/create/photo',
		photos: '/posts/photos/:id',
		adminUsers: '/users/admin/:id',
		updateUser: '/users/:id',
		deleteUser: '/users/:id',
	},
	API_URL: process.env.REACT_APP_API_URL as string,
};
