import AWS from 'aws-sdk';
import { useMutation } from 'react-query';
import { toastConfig } from 'utils/toast.config';
import { urlConfig } from 'utils/url-config';

AWS.config.update({
	accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
	secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
});

const s3 = new AWS.S3();

const useAddPhoto = () => {
	const create = async ({
		photoData,
		postId,
		refetch,
		setPostPhotos,
	}: {
		photoData: any;
		postId: string | undefined;
		refetch?: () => void;
		setPostPhotos?: (photos: File[]) => void;
	}) => {
		const uploadFile = (params: AWS.S3.PutObjectRequest) => {
			return new Promise((resolve, reject) => {
				s3.upload(params, async (err, data) => {
					if (err) {
						reject(err);
					} else {
						try {
							const photoResponse = await fetch(`${urlConfig.API_URL}${urlConfig.api.createPhoto}`, {
								method: 'POST',
								headers: {
									'Content-Type': 'application/json',
									Authorization: 'Bearer ' + localStorage.getItem('token'),
								},
								body: JSON.stringify({
									photo: data?.Location,
									postId: postId,
									photoBucketKey: data?.Key,
								}),
							});

							if (!photoResponse.ok) {
								toastConfig.post.create.error();
								throw new Error('Failed to upload photo');
							}
							resolve(data);
						} catch (error) {
							console.error('Error uploading photo to API:', error);
							reject(error);
						}
					}
				});
			});
		};

		for (const photo of photoData.photos) {
			const params: AWS.S3.Types.PutObjectRequest = {
				Bucket: process.env.REACT_APP_S3_BUCKET_NAME as string,
				Key: new Date().getTime() + photo.name,
				Body: photo,
			};

			await uploadFile(params);
		}

		toastConfig.photos.create.success();
		refetch?.();
		setPostPhotos?.([]);
	};

	return useMutation(create);
};

export default useAddPhoto;
