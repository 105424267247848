import { useMutation } from 'react-query';
import { toastConfig } from 'utils/toast.config';
import { urlConfig } from 'utils/url-config';

const useUpdateMedia = () => {
	const updatePost = async (postData: any) => {
		for (const photo of postData.post_photos) {
			const photoResponse = await fetch(`${urlConfig.API_URL}${urlConfig.api.photos.replace(':id', photo.photo_id)}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
				body: JSON.stringify({
					photo_showed_number: photo.photo_showed_number,
				}),
			});

			if (!photoResponse.ok) {
				toastConfig.photos.update.error();
				throw new Error('Failed to upload photo');
			}
		}
	};

	return useMutation(updatePost, {
		onSuccess: () => {
			toastConfig.photos.update.success();
		},
		onError: () => {
			toastConfig.photos.update.error();
		},
	});
};

export default useUpdateMedia;
