import { Badge } from 'components/ui/badge';
import ApartmentsWrapper from 'modules/apartments/apartments-wrapper';
import { useEffect, useState } from 'react';
import { usePostsStore } from 'stores/post-store';
import { cn } from 'utils/cn';
import { urlConfig } from 'utils/url-config';
import Map from '../modules/map/map';

const MainPage = () => {
	const postId = usePostsStore((state) => state.postId);
	const openFilter = usePostsStore((state) => state.openFilter)
	const setFromMobilePin = usePostsStore((state) => state.setFromMobilePin);
	const [mapView, setMapView] = useState<boolean>(window.innerWidth >= 768);

	const path = window.location.pathname;

	useEffect(() => {
		const handleResize = () => {
			setMapView(window.innerWidth >= 768);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className="flex relative max-h-svh">
			<Map className={cn('md:w-3/5', mapView ? 'w-full' : 'w-0')} setMapView={setMapView} />
			<ApartmentsWrapper className={cn('md:w-2/5', mapView ? 'w-0' : 'w-full')} />
			{(path === urlConfig.pages.main ||
				path === urlConfig.pages.addPost ||
				path.includes(urlConfig.pages.updatePost.replace(':id', ''))) && (
				<>
				{!openFilter && 
					<div>
					{mapView ? (
						<Badge
							variant="secondary"
							className={cn(
								'absolute right-1/2 transform translate-x-1/2 bottom-2',
								'flex items-center gap-2',
								'text-base bg-white shadow-xl hover:bg-white',
								'py-2 px-3',
								'flex md:hidden'
							)}
							onClick={() => {
								setMapView(false);
								setFromMobilePin(false);
							}}>
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<mask
									id="mask0_77_484"
									style={{ maskType: 'alpha' }}
									maskUnits="userSpaceOnUse"
									x="0"
									y="0"
									width="24"
									height="24">
									<rect width="24" height="24" fill="#D9D9D9" />
								</mask>
								<g mask="url(#mask0_77_484)">
									<path
										d="M2 20V16H6V20H2ZM8 20V16H22V20H8ZM2 14V10H6V14H2ZM8 14V10H22V14H8ZM2 8V4H6V8H2ZM8 8V4H22V8H8Z"
										fill="#131415"
									/>
								</g>
							</svg>
							{path === urlConfig.pages.addPost || path.includes(urlConfig.pages.updatePost.replace(':id', '')) ? (
								<>Post</>
							) : (
								<>List view</>
							)}
						</Badge>
					) : (
						<Badge
							variant="secondary"
							className={cn(
								'absolute right-1/2 transform translate-x-1/2 bottom-2',
								'flex items-center gap-2',
								'text-base bg-white shadow-xl hover:bg-white',
								'py-2 px-3',
								'flex md:hidden'
							)}
							onClick={() => setMapView(true)}>
							<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M12 18L6 15.9L1.35 17.7C1.01667 17.8333 0.708333 17.7958 0.425 17.5875C0.141667 17.3792 0 17.1 0 16.75V2.75C0 2.53333 0.0625 2.34167 0.1875 2.175C0.3125 2.00833 0.483333 1.88333 0.7 1.8L6 0L12 2.1L16.65 0.3C16.9833 0.166667 17.2917 0.204167 17.575 0.4125C17.8583 0.620833 18 0.9 18 1.25V15.25C18 15.4667 17.9375 15.6583 17.8125 15.825C17.6875 15.9917 17.5167 16.1167 17.3 16.2L12 18ZM11 15.55V3.85L7 2.45V14.15L11 15.55ZM13 15.55L16 14.55V2.7L13 3.85V15.55ZM2 15.3L5 14.15V2.45L2 3.45V15.3Z"
									fill="#131415"
								/>
							</svg>
							Map view
							{postId && (
								<span className={cn('absolute -top-1 -right-1 rounded-full bg-red-500 w-4 h-4 text-xs text-center text-white')}>
									1
								</span>
							)}
						</Badge>
					)}
					</div>
				}
				</>
			)}
		</div>
	);
};
export default MainPage;
